import httpClient from 'http/httpClient'
import {
  Alert,
  AlertRequestBody,
  ReportingService as IReportingService,
  InAppAlert,
  Metrics,
  NotificationId,
  ToggleAlert,
  UpdateAlert,
} from 'types/reporting.model'

const SubscriptionService = (): IReportingService => {
  return {
    createAlert: (request: AlertRequestBody): HttpPromise<AlertRequestBody> => {
      return httpClient.post(`/api/reporting/alerts/`, {
        data: request,
      })
    },
    getMetrics: (companyId: string): HttpPromise<Metrics> => {
      return httpClient.get(`/api/reporting/alerts/${companyId}/metrics`)
    },
    getDataSources: (companyId: string): HttpPromise<Metrics> => {
      return httpClient.get(`/api/reporting/alerts/${companyId}/data-sources`)
    },
    getAlertById: (id: string): HttpPromise<Alert> => {
      return httpClient.get(`/api/reporting/alert/${id}`)
    },
    getAlerts: (companyId: string): HttpPromise<Alert[]> => {
      return httpClient.get(`/api/reporting/alerts/${companyId}/`)
    },
    toggleAlert: (id: string): HttpPromise<ToggleAlert> => {
      return httpClient.put(`/api/reporting/alerts/${id}/toggle-alert/`)
    },
    deleteAlert: (id: string): HttpPromise<ToggleAlert> => {
      return httpClient.delete(`/api/reporting/alerts/${id}/`)
    },
    updateAlert: (request: UpdateAlert): HttpPromise<AlertRequestBody> => {
      return httpClient.put(`/api/reporting/alerts/${request.id}/`, {
        data: request.body,
      })
    },
    getInAppAlerts: (companyId: string): HttpPromise<InAppAlert[]> => {
      return httpClient.get(`/api/reporting/alerts/${companyId}/in-app-alerts/`)
    },
    getAllNotifications: (): HttpPromise<InAppAlert[]> => {
      return httpClient.get(`api/dashboard/notifications/list/`)
    },
    patchReadNotification: (
      request: NotificationId,
    ): HttpPromise<InAppAlert[]> => {
      return httpClient.patch(`api/dashboard/notifications/mark-as-read/`, {
        data: request,
      })
    },
    getAllNotificationsCount: (): HttpPromise<InAppAlert[]> => {
      return httpClient.get(
        `api/dashboard/notifications/unread-notification-count/`,
      )
    },
  }
}

export default SubscriptionService()
