import httpClient from 'http/httpClient'
import { graphQuery } from 'types/dashboard.model'
import {
  BestProgressAllocated,
  BestProgressPaidout,
  SalesService as ISalesService,
  IndividualDetails,
  IndividualStats,
  OverallStats,
  SalesMetrics,
  SalesPerformance,
  SalesRevenueSummary,
  SalesTeamMemberList,
  SalesVoulume,
  SourceStatus,
  SourceStatusInManager,
  TeamDetails,
  TeamMemberList,
  TeamPerformanceSummery,
  TeamRating,
} from 'types/sales.model'

const SalesService = (): ISalesService => {
  return {
    getSalesVolume: (request: graphQuery): HttpPromise<SalesVoulume> => {
      return httpClient.get(
        `/api/sales/overview/${request.id}/sales-volume/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getSalesMetrics: (request: graphQuery): HttpPromise<SalesMetrics> => {
      return httpClient.get(
        `/api/sales/overview/${request.id}/sales-metrics/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getSalesRevenueSummary: (
      request: graphQuery,
    ): HttpPromise<SalesRevenueSummary> => {
      return httpClient.get(
        `/api/sales/overview/${request.id}/sales-revenue-summary/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getSourceStatus: (request: graphQuery): HttpPromise<SourceStatus[]> => {
      return httpClient.get(
        `/api/sales/overview/${request.id}/source-status/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getSalesPerformance: (
      request: graphQuery,
    ): HttpPromise<SalesPerformance> => {
      return httpClient.get(
        `/api/sales/overview/${request.id}/sales-performance/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getTeamRating: (request: graphQuery): HttpPromise<TeamRating> => {
      return httpClient.get(
        `/api/sales/team-management/${request.id}/team-aggregate/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getSalesTeamOverview: (request: graphQuery): HttpPromise<TeamRating> => {
      return httpClient.get(
        `/api/sales/team-management/${request.id}/team-overview-list/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getTeamLeaderboard: (
      request: graphQuery,
    ): HttpPromise<TeamMemberList[]> => {
      return httpClient.get(
        `/api/sales/team-management/${request.id}/team-members-list/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getSourcesStatusInManager: (
      request: graphQuery,
    ): HttpPromise<SourceStatusInManager> => {
      return httpClient.get(
        `/api/sales/team-management/${request.id}/team-source-status/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getBestProgressAllocated: (
      request: graphQuery,
    ): HttpPromise<BestProgressAllocated[]> => {
      return httpClient.get(
        `/api/sales/team-management/${request.id}/team-best-allocated/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getBestProgressPaidout: (
      request: graphQuery,
    ): HttpPromise<BestProgressPaidout[]> => {
      return httpClient.get(
        `/api/sales/team-management/${request.id}/team-best-paidout/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getOverallStats: (request: graphQuery): HttpPromise<OverallStats[]> => {
      return httpClient.get(
        `/api/sales/team-management/${request.id}/team-overall-status/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getTeamPerformanceSummery: (
      request: graphQuery,
    ): HttpPromise<TeamPerformanceSummery> => {
      return httpClient.get(
        `/api/sales/team-management/${request.id}/team-performance-summary/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getIndividualDetails: (
      request: graphQuery,
    ): HttpPromise<IndividualDetails[]> => {
      return httpClient.get(
        `/api/sales/team-management/${request.id}/individual-performance/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getLeaderBoardIndividual: (
      request: graphQuery,
    ): HttpPromise<TeamMemberList[]> => {
      return httpClient.get(
        `/api/sales/team-management/${request.id}/team-members-list/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getSourcesStatusInIndividual: (
      request: graphQuery,
    ): HttpPromise<SourceStatusInManager> => {
      return httpClient.get(
        `/api/sales/team-management/${request.id}/individual-source-status/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getTeamIndividualSummery: (
      request: graphQuery,
    ): HttpPromise<TeamPerformanceSummery> => {
      return httpClient.get(
        `/api/sales/team-management/${
          request.id
        }/individual-performance-summary/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getSalesTeamMemberList: (
      request: graphQuery,
    ): HttpPromise<SalesTeamMemberList[]> => {
      return httpClient.get(
        `/api/sales/team-management/${request.id}/sales-team-members-list/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getTeamDetails: (teamId: string): HttpPromise<TeamDetails> => {
      return httpClient.get(
        `/api/sales/team-management/${teamId}/team-details/`,
      )
    },
    getIndividualStats: (request: graphQuery): HttpPromise<IndividualStats> => {
      return httpClient.get(
        `/api/sales/team-management/${request.id}/individual-stats/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
  }
}

export default SalesService()
