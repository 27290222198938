import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Step } from 'pages/Workspace/Workspace'
import workspaceService from 'store/services/workspaceService'
import {
  FunnelStages,
  GetOtherSourceFullList,
  MandatoryField,
  OtherSourceDataItem,
  RunTestResponse,
  SaveFunnelStages,
  TestErrorLogsResponse,
  UpdateConfigurationResponse,
  UtmModalData,
  errorGraph,
  getConfigurationResponse,
} from 'types/workspace.model'

export interface WorkspaceSlice {
  configuration: UpdateConfigurationResponse | null
  getconfigurationDetails: getConfigurationResponse
  testResponse: RunTestResponse | null
  stageId: string
  stageName: string
  funnelId: string
  funnelName: string
  workspaceCompleted: boolean
  workspaceCompletedHome: boolean
  totalStep: number
  currentStep: Step
  stages: SaveFunnelStages[]
  otherSourcesList: GetOtherSourceFullList[]
  loading: boolean
  paidSourceAccountLoading: boolean
  error: any
  debugging: TestErrorLogsResponse
  errorGraph: errorGraph
  //stageTypes: StageType
  unmappedOtherSource: OtherSourceDataItem[]
  sourceUtmData: UtmModalData
  bucketLoading: boolean
  dataFields: MandatoryField[]
}

const initState: WorkspaceSlice = {
  configuration: null,
  getconfigurationDetails: {
    name: '',
    url: '',
    data_fields: [],
    stage_type: '',
    is_sales_person_assigned: false,
    is_final_step: false,
  },
  testResponse: null,
  stageId: '',
  stageName: '',
  funnelId: '',
  funnelName: '',
  totalStep: 0,
  currentStep: 1,
  workspaceCompleted: false,
  workspaceCompletedHome: true,
  stages: [
    {
      id: '',
      name: '',
      order: 0,
      configureId: '',
      type: '',
      metric_name: '',
      is_final_step: false,
      is_sales_person_assigned: false,
    },
  ],
  otherSourcesList: [
    {
      name: '',
      id: '',
      amount: '',
      source_type: '',
      unit_or_stage: '',
      utm_keywords: [],
      company: '',
    },
  ],
  loading: false,
  error: null,
  debugging: {
    test_error_logs: [],
    events_received: 0,
    events_failed: 0,
    failure_rate: 0,
    trend_issues: [],
  },
  errorGraph: { failed: [], success: [] },
  //stageTypes: {},
  unmappedOtherSource: [],
  paidSourceAccountLoading: false,
  sourceUtmData: {
    id: '',
    other_sources: [],
    unmapped_utmsources: [],
    source_name: '',
    funnel: '',
  },
  bucketLoading: false,
  dataFields: [],
}

export const createFunnel = createAsyncThunk(
  'workspace/createFunnel',
  workspaceService.createFunnel,
)

export const CreateCompany = createAsyncThunk(
  'workspace/createCompany',
  workspaceService.postCreateCompany,
)

export const updateConfiguration = createAsyncThunk(
  'workspace/updateConfiguration',
  workspaceService.updateConfiguration,
)

export const getConfiguration = createAsyncThunk<
  getConfigurationResponse,
  { stageId: string },
  { rejectValue: string }
>('workspace/getConfiguration', async ({ stageId }, thunkAPI) => {
  try {
    const response = await workspaceService.getConfiguration(stageId)
    return response.data
  } catch (error) {
    return thunkAPI.rejectWithValue('Failed to get configuration.')
  }
})

// export const deleteStage = createAsyncThunk<
//   DeleteStageResponse,
//   { funnelId: string; stageId: string },
//   { rejectValue: string }
// >('workspace/deleteStage', async ({ funnelId, stageId }, thunkAPI) => {
//   try {
//     const response = await workspaceService.deleteStage(funnelId, stageId)
//     return response.data
//   } catch (error) {
//     return thunkAPI.rejectWithValue('Failed to delete stage.')
//   }
// })

export const deleteStage = createAsyncThunk(
  'workspace/deleteStage',
  workspaceService.deleteStage,
)

export const runTest = createAsyncThunk(
  'workspace/runTest',
  workspaceService.runTest,
)

// export const runTest = createAsyncThunk(
//   'workspace/runTest',
//   workspaceService.runTest,
// )

export const updateFunnel = createAsyncThunk(
  'workspace/updateFunnel',
  workspaceService.updateFunnel,
)

export const getFunnelProgress = createAsyncThunk(
  'workspace/funnelProgress',
  workspaceService.getFunnelProgress,
)

export const createFunnelStage = createAsyncThunk(
  'workspace/createFunnelStage',
  workspaceService.createStage,
)

export const postNewCreateConfiguration = createAsyncThunk(
  'workspace/postNewCreateConfiguration',
  workspaceService.postNewCreateConfiguration,
)

export const updateFunnelStage = createAsyncThunk(
  'workspace/updateFunnelStage',
  workspaceService.updateStage,
)

export const saveFunnelStages = createAsyncThunk(
  'workspace/saveFunnelStages',
  workspaceService.saveFunnelStages,
)

export const getFunnelStages = createAsyncThunk(
  'workspace/getFunnelStages',
  workspaceService.getStages,
)

export const getWebhookUrl = createAsyncThunk(
  'workspace/getWebhookUrl',
  workspaceService.getWebhookUrl,
)

export const getMandatoryFields = createAsyncThunk(
  'workspace/getMandatory',
  workspaceService.getMandatoryFields,
)

export const getPaidSources = createAsyncThunk(
  'workspace/getPaidSources',
  workspaceService.getPaidSources,
)

export const postNewPaidSources = createAsyncThunk(
  'workspace/postNewPaidSources',
  workspaceService.postNewPaidSources,
)

export const postGooglePayload = createAsyncThunk(
  'workspace/postGooglePayload',
  workspaceService.postGooglePayload,
)

export const postReconfigurePaidSource = createAsyncThunk(
  'workspace/postReconfigurePaidSource',
  workspaceService.postReconfigurePaidSource,
)

export const getTargetValues = createAsyncThunk(
  'workspace/getTargetValues',
  workspaceService.getTargetValues,
)

export const postSavePaidSources = createAsyncThunk(
  'workspace/postSavePaidSources',
  workspaceService.postSavePaidSources,
)
export const postOtherSources = createAsyncThunk(
  'workspace/postOtherSources',
  workspaceService.postOtherSources,
)

export const getOtherSources = createAsyncThunk(
  'workspace/getOtherSources',
  workspaceService.getOtherSources,
)

export const postUnmappedOtherSources = createAsyncThunk(
  'workspace/postUnmappedOtherSources',
  workspaceService.postUnmappedOtherSources,
)

export const putOtherSources = createAsyncThunk(
  'workspace/putOtherSources',
  workspaceService.putOtherSources,
)

export const getOtherSourcesList = createAsyncThunk(
  'workspace/getOtherSourcesList',
  workspaceService.getOtherSourcesList,
)

export const deleteOtherSource = createAsyncThunk(
  'workspace/deleteOtherSource',
  workspaceService.deleteOtherSource,
)

export const postSaveOtherSources = createAsyncThunk(
  'workspace/saveOtherSources',
  workspaceService.postSaveOtherSources,
)

export const getTestErrorLogs = createAsyncThunk(
  'workspace/getTestErrorLogs',
  async (companyId: string, { rejectWithValue }) => {
    const response = await workspaceService.getTestErrorLogs(companyId)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getUnmapedOtherSource = createAsyncThunk(
  'workspace/getUnmappedOtherSource',
  async (companyId: string, { rejectWithValue }) => {
    const response = await workspaceService.getUnmapedOtherSource(companyId)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getUnmappedSourceUtmData = createAsyncThunk(
  'workspace/getUnmappedUtmSourceData',
  async (sourceId: string, { rejectWithValue }) => {
    const response = await workspaceService.getUnmappedSourceUtmData(sourceId)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const putUnmappedOtherSource = createAsyncThunk(
  'workspace/putUnmappedOtherSource',
  workspaceService.putUnmappedOtherSource,
)

export const getErrorGraph = createAsyncThunk(
  'workspace/getErrorGraph',
  async (companyId: string, { rejectWithValue }) => {
    const response = await workspaceService.getErrorGraph(companyId)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

// export const getStageTypes = createAsyncThunk(
//   'workspace/getStageTypes',
//   async (_, { rejectWithValue }) => {
//     const response = await workspaceService.getStageTypes()
//     if (response.status === 200) {
//       return response
//     } else {
//       rejectWithValue(await response.json())
//       return response
//     }
//   },
// )

export const putSelectedAccount = createAsyncThunk(
  'workspace/putSelectdAccount',
  workspaceService.putSelectedAccount,
)

export const deleteBucket = createAsyncThunk(
  'workspace/deleteBucket',
  workspaceService.deleteBucket,
)

const workspaceSlice = createSlice({
  name: 'workspace',
  initialState: initState,
  reducers: {
    setFunnelName: (state, action: PayloadAction<string>) => {
      state.funnelName = action.payload
    },
    addStage: (state) => {
      state.stages = [
        ...state.stages,
        {
          id: '',
          name: '',
          order: state.stages.length,
          configureId: '',
          type: '',
          metric_name: '',
          is_final_step: false,
          is_sales_person_assigned: false,
        },
      ]
    },
    setLastStageUpdate: (
      state,
      action: PayloadAction<{ stageId: string; isFinal: boolean }>,
    ) => {
      const { stageId, isFinal } = action.payload
      const stage = state.stages.find((stage) => stage.id === stageId)
      if (stage) {
        stage.is_final_step = isFinal
      }
    },
    setSalesPersonAssigned: (
      state,
      action: PayloadAction<{ stageId: string; isAssigned: boolean }>,
    ) => {
      const { stageId, isAssigned } = action.payload
      const stage = state.stages.find((stage) => stage.id === stageId)
      if (stage) {
        stage.is_sales_person_assigned = isAssigned
      }
    },
    addOtherPaidSource: (state) => {
      const newOtherSource = {
        name: '',
        id: '',
        amount: 0,
        source_type: '',
        unit_or_stage: '',
        targets: [],
        utm_keywords: [],
        company: '',
      }
      // state.otherSourcesList.push(newOtherSource)
      const updatedOtherSourcesList = [
        ...state.otherSourcesList,
        newOtherSource,
      ] // Create a new array
      state.otherSourcesList = updatedOtherSourcesList
    },

    setStageName: (
      state,
      action: PayloadAction<{ value: string; index: number }>,
    ) => {
      state.stages[action.payload.index].name = action.payload.value
    },
    setOtherSourceName: (
      state,
      action: PayloadAction<{ value: string; index: number }>,
    ) => {
      state.otherSourcesList[action.payload.index].name = action.payload.value
    },

    setOtherSourceAmount: (
      state,
      action: PayloadAction<{ value: number | string; index: number }>,
    ) => {
      state.otherSourcesList[action.payload.index].amount = action.payload.value
    },

    setOtherSourceSourceName: (
      state,
      action: PayloadAction<{ value: string; index: number }>,
    ) => {
      state.otherSourcesList[action.payload.index].source_type =
        action.payload.value
    },

    setOtherSourceUnit: (
      state,
      action: PayloadAction<{ value: string; index: number }>,
    ) => {
      state.otherSourcesList[action.payload.index].unit_or_stage =
        action.payload.value
    },

    setStages: (state, action) => {
      state.stages = action.payload
    },

    setOtherSourceList: (state, action) => {
      state.otherSourcesList = action.payload
    },

    reset: () => {
      return initState
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(updateConfiguration.pending, (state) => {
        state.loading = true
      })
      .addCase(updateConfiguration.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.configuration = action.payload
      })
      .addCase(updateConfiguration.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
    builder
      .addCase(getConfiguration.pending, (state) => {
        state.loading = true
      })
      .addCase(getConfiguration.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.getconfigurationDetails = action.payload
      })
      .addCase(getConfiguration.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
    builder
      .addCase(deleteStage.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteStage.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(deleteStage.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
    builder
      .addCase(createFunnel.pending, (state) => {
        state.loading = true
      })
      .addCase(createFunnel.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.funnelId = action.payload.data.id
        state.funnelName = action.payload.data.name
      })
      .addCase(createFunnel.rejected, (state, action) => {
        state.loading = false
        state.funnelId = ''
        state.funnelName = ''
        state.error = action.payload
      })
    builder
      .addCase(getFunnelProgress.pending, (state) => {
        state.loading = true
      })
      .addCase(getFunnelProgress.fulfilled, (state, action) => {
        state.loading = false
        state.error = null

        // Check if payload and necessary data exist before accessing them
        const data = action.payload?.data

        if (data && data.data) {
          state.funnelId = data.data.funnel_id || null
          state.funnelName = data.data.funnel_name || ''
          state.currentStep = data.current_step || 0
          state.totalStep = data.total_step || 0
          state.workspaceCompleted = data.is_setup_completed || false
          state.workspaceCompletedHome = data.is_setup_completed || false
        } else {
          state.error = 'Invalid response structure'
        }
      })
      .addCase(getFunnelProgress.rejected, (state, action) => {
        state.loading = false
        state.error = action.error
      })
    builder
      .addCase(createFunnelStage.pending, (state) => {
        state.loading = true
      })
      .addCase(createFunnelStage.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.stages = state.stages.map((stage) =>
          stage.order === action.payload.data.order
            ? { ...stage, id: action.payload.data.id }
            : stage,
        )
      })
      .addCase(createFunnelStage.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
    builder
      .addCase(updateFunnelStage.pending, (state) => {
        state.loading = true
      })
      .addCase(updateFunnelStage.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.stageName = action.payload.data.name
        state.stageId = action.payload.data.id
      })
      .addCase(updateFunnelStage.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
    builder
      .addCase(postNewCreateConfiguration.pending, (state) => {
        state.loading = true
      })
      .addCase(postNewCreateConfiguration.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.stages = state.stages.map((stage) =>
          stage.id === action.payload.data.stage_id
            ? { ...stage, configureId: action.payload.data.id }
            : stage,
        )
      })
      .addCase(postNewCreateConfiguration.rejected, (state, action) => {
        state.loading = false

        state.error = action.payload
      })
    builder
      .addCase(getWebhookUrl.pending, (state) => {
        state.loading = true
      })
      .addCase(getWebhookUrl.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(getWebhookUrl.rejected, (state, action) => {
        state.loading = false

        state.error = action.payload
      })
    builder
      .addCase(getMandatoryFields.pending, (state) => {
        state.loading = true
      })
      .addCase(getMandatoryFields.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.dataFields = action.payload.data
      })
      .addCase(getMandatoryFields.rejected, (state, action) => {
        state.loading = false

        state.error = action.payload
      })
    builder
      .addCase(CreateCompany.pending, (state) => {
        state.loading = true
      })
      .addCase(CreateCompany.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(CreateCompany.rejected, (state, action) => {
        state.loading = false

        state.error = action.payload
      })
    builder
      .addCase(getFunnelStages.pending, (state) => {
        state.loading = true
      })
      .addCase(getFunnelStages.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.stages = action.payload.data.map((stage: FunnelStages) => {
          return {
            id: stage.id,
            name: stage.name,
            order: stage.order,
            configurationId: stage.configuration_id,
            type: stage.type,
            is_sales_person_assigned: stage.is_sales_person_assigned,
            is_final_step: stage.is_final_step,
          }
        })
      })
      .addCase(getFunnelStages.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
    builder
      .addCase(getPaidSources.pending, (state) => {
        state.loading = true
      })
      .addCase(getPaidSources.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(getPaidSources.rejected, (state, action) => {
        state.loading = false

        state.error = action.payload
      })
    builder
      .addCase(postNewPaidSources.pending, (state) => {
        state.loading = true
      })
      .addCase(postNewPaidSources.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(postNewPaidSources.rejected, (state, action) => {
        state.loading = false

        state.error = action.payload
      })
    builder
      .addCase(postGooglePayload.pending, (state) => {
        state.paidSourceAccountLoading = true
      })
      .addCase(postGooglePayload.fulfilled, (state) => {
        state.paidSourceAccountLoading = false
        state.error = null
      })
      .addCase(postGooglePayload.rejected, (state, action) => {
        state.paidSourceAccountLoading = false

        state.error = action.payload
      })
    builder
      .addCase(postReconfigurePaidSource.pending, (state) => {
        state.paidSourceAccountLoading = true
      })
      .addCase(postReconfigurePaidSource.fulfilled, (state) => {
        state.paidSourceAccountLoading = false
        state.error = null
      })
      .addCase(postReconfigurePaidSource.rejected, (state, action) => {
        state.paidSourceAccountLoading = false
        state.error = action.payload
      })
    builder
      .addCase(getTargetValues.pending, (state) => {
        state.loading = true
      })
      .addCase(getTargetValues.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(getTargetValues.rejected, (state, action) => {
        state.loading = false

        state.error = action.payload
      })
    builder
      .addCase(postSavePaidSources.pending, (state) => {
        state.loading = true
      })
      .addCase(postSavePaidSources.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(postSavePaidSources.rejected, (state, action) => {
        state.loading = false

        state.error = action.payload
      })
    builder
      .addCase(postOtherSources.pending, (state) => {
        state.loading = true
      })
      .addCase(postOtherSources.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.otherSourcesList = state.otherSourcesList.map((sourceList) =>
          sourceList.name === action.payload.data.name
            ? { ...sourceList, id: action.payload.data.id }
            : sourceList,
        )
      })
      .addCase(postOtherSources.rejected, (state, action) => {
        state.loading = false

        state.error = action.payload
      })
    builder
      .addCase(getOtherSources.pending, (state) => {
        state.loading = true
      })
      .addCase(getOtherSources.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(getOtherSources.rejected, (state, action) => {
        state.loading = false

        state.error = action.payload
      })
    builder
      .addCase(putOtherSources.pending, (state) => {
        state.loading = true
      })
      .addCase(putOtherSources.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(putOtherSources.rejected, (state, action) => {
        state.loading = false

        state.error = action.payload
      })
    builder
      .addCase(getOtherSourcesList.pending, (state) => {
        state.loading = true
      })

      .addCase(getOtherSourcesList.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        if (action.payload.data.length > 0) {
          state.otherSourcesList = action.payload.data.map(
            (stage: GetOtherSourceFullList) => {
              return {
                id: stage.id,
                name: stage.name,
                amount: stage.amount,
                company: stage.company,
                utm_keywords: stage.utm_keywords,
                source_type: stage.source_type,
                unit_or_stage: stage.unit_or_stage,
              }
            },
          )
        }
      })
      .addCase(getOtherSourcesList.rejected, (state, action) => {
        state.loading = false

        state.error = action.payload
      })
    builder
      .addCase(deleteOtherSource.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteOtherSource.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(deleteOtherSource.rejected, (state, action) => {
        state.loading = false

        state.error = action.payload
      })
    builder
      .addCase(postSaveOtherSources.pending, (state) => {
        state.loading = true
      })
      .addCase(postSaveOtherSources.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(postSaveOtherSources.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
    builder
      .addCase(getTestErrorLogs.pending, (state) => {
        state.loading = true
      })
      .addCase(getTestErrorLogs.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.debugging = action.payload.data
      })
      .addCase(getTestErrorLogs.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
    builder
      .addCase(getErrorGraph.pending, (state) => {
        state.loading = true
      })
      .addCase(getErrorGraph.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.errorGraph = action.payload.data
      })
      .addCase(getErrorGraph.rejected, (state, action) => {
        state.loading = false
        state.errorGraph = { success: [], failed: [] }
        state.error = action.payload
      })
    builder
      .addCase(putSelectedAccount.pending, (state) => {
        state.loading = true
      })
      .addCase(putSelectedAccount.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(putSelectedAccount.rejected, (state, action) => {
        state.loading = false

        state.error = action.payload
      })
    builder
      .addCase(getUnmapedOtherSource.pending, (state) => {
        state.loading = true
      })
      .addCase(getUnmapedOtherSource.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.unmappedOtherSource = action.payload.data
      })
      .addCase(getUnmapedOtherSource.rejected, (state, action) => {
        state.loading = false
        state.unmappedOtherSource = []
        state.error = action.payload
      })
    builder
      .addCase(getUnmappedSourceUtmData.pending, (state) => {
        state.loading = true
      })
      .addCase(getUnmappedSourceUtmData.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.sourceUtmData = action.payload.data
      })
      .addCase(getUnmappedSourceUtmData.rejected, (state, action) => {
        state.loading = false
        state.sourceUtmData = {
          id: '',
          other_sources: [],
          unmapped_utmsources: [],
          source_name: '',
          funnel: '',
        }
        state.error = action.payload
      })
    builder
      .addCase(postUnmappedOtherSources.pending, (state) => {
        state.bucketLoading = true
      })
      .addCase(postUnmappedOtherSources.fulfilled, (state) => {
        state.bucketLoading = false
        state.error = null
      })
      .addCase(postUnmappedOtherSources.rejected, (state, action) => {
        state.bucketLoading = false
        state.error = action.payload
      })
    builder
      .addCase(putUnmappedOtherSource.pending, (state) => {
        state.bucketLoading = true
      })
      .addCase(putUnmappedOtherSource.fulfilled, (state) => {
        state.bucketLoading = false
        state.error = null
      })
      .addCase(putUnmappedOtherSource.rejected, (state, action) => {
        state.bucketLoading = false
        state.error = action.payload
      })
    builder
      .addCase(deleteBucket.pending, (state) => {
        state.bucketLoading = true
      })
      .addCase(deleteBucket.fulfilled, (state) => {
        state.bucketLoading = false
        state.error = null
      })
      .addCase(deleteBucket.rejected, (state, action) => {
        state.bucketLoading = false
        state.error = action.payload
      })
  },
})

export const {
  setFunnelName,
  addStage,
  setStageName,
  setStages,
  setOtherSourceName,
  setOtherSourceSourceName,
  setOtherSourceUnit,
  setOtherSourceAmount,
  reset,
  addOtherPaidSource,
  setOtherSourceList,
  setLastStageUpdate,
  setSalesPersonAssigned,
} = workspaceSlice.actions

export default workspaceSlice.reducer
