import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'
import dashboardService from 'store/services/dashboardService'
import {
  adGroupId,
  costData,
  funnelPerformanceDetails,
  graphQuery,
  revenueData,
  spendData,
  trendData,
  PaidSourceConfig,
  LeadVsApprovalData,
  filters,
  CampaignData,
  AdsData,
  graphQueryWithSource,
  funnelQuerry,
  TargetMetData,
  paidSourceHistoricData,
  funnelPerformanceQuerry,
  TargetMetAverage,
  campaignOverview,
  historicAndDailyTrendData,
  IndividualRating,
  stageNames,
  DashboardTeamList,
  OtherSourceList,
  OtherSourceOverview,
  TotalTrendsData,
  RevenueOverviewResponse,
  SpendOverviewResponse,
  rollingAverageData,
} from 'types/dashboard.model'
import {
  FILTER_SELECTED,
  DISPLAY_FILTER,
  TARGET_FILTER_SELECTED,
  START_DATE_RANGE,
  END_DATE_RANGE,
} from 'Constants'

export interface DashboardSlice {
  loading: boolean
  error: any
  funnelPerformanceDashboardData: funnelPerformanceDetails
  funnelLoading: boolean
  funnelPerformanceMarketingData: funnelPerformanceDetails
  funnelPerformancePaidSourceData: funnelPerformanceDetails
  funnelPerformanceOtherSourceData: funnelPerformanceDetails
  costData: costData
  revenueData: revenueData[]
  spendData: spendData[]
  leadVsApprovalData: LeadVsApprovalData[]
  campaignLoading: boolean
  trendData: trendData[]
  facebookTrendData: historicAndDailyTrendData[]
  facebookDailyData: historicAndDailyTrendData[]
  facebookLeadCount: number
  tiktokTrendData: historicAndDailyTrendData[]
  tiktokDailyData: historicAndDailyTrendData[]
  tiktokLeadCount: number
  googleTrendData: historicAndDailyTrendData[]
  googleDailyData: historicAndDailyTrendData[]
  TotalTrendsData: TotalTrendsData[]
  googleLeadCount: number
  paidSourceConfigList: PaidSourceConfig[]
  filters: filters[]
  campaignData: CampaignData[]
  adsGroupsData: CampaignData[]
  adsData: AdsData[]
  keywordsData: CampaignData[]
  targetMetData: TargetMetData[]
  targetMetAverageData: TargetMetAverage[]
  paidSourceRevenueGraphData: paidSourceHistoricData[]
  paidSourceSpendGraphData: paidSourceHistoricData[]
  revenue_sales: number
  revenue: number
  revenuePercentage: number
  spend_sales: number
  spend: number
  spendPercentage: number
  campaignOverviewData: campaignOverview[]
  rollingAverageData: rollingAverageData[]
  individualRating: IndividualRating[]
  stageNames: stageNames
  teamRatingList: DashboardTeamList[]
  campaignOverviewLoading: boolean
  unconfiguredOtherSourceList: OtherSourceList
  unconfiguredOtherSourceListLoading: boolean
  otherSourceOverview: OtherSourceOverview[]
  otherSourceRevenueGraphData: paidSourceHistoricData[]
  otherSourceRevenueSales: number
  otherSourceRevenue: number
  otherSourceRevenuePercentage: number
  targetMetError: any
  adsGroupLoading: boolean
  revenueOverview: RevenueOverviewResponse
  spendOverview: SpendOverviewResponse
  filterSelected: string
  displayFilterSelected: string
  targetFilterSelected: string
  startDateRange: string
  endDateRange: string
}

const initState: DashboardSlice = {
  loading: false,
  campaignLoading: false,
  error: null,
  funnelPerformanceDashboardData: {
    funnel_data: {},
    overall_conversion_time: '',
    prev_overall_conversion_time: '',
    revenue: 0,
  },
  funnelLoading: false,
  funnelPerformanceMarketingData: {
    funnel_data: {},
    overall_conversion_time: '',
    prev_overall_conversion_time: '',
    revenue: 0,
  },
  funnelPerformancePaidSourceData: {
    funnel_data: {},
    overall_conversion_time: '',
    prev_overall_conversion_time: '',
    revenue: 0,
  },
  funnelPerformanceOtherSourceData: {
    funnel_data: {},
    overall_conversion_time: '',
    prev_overall_conversion_time: '',
    revenue: 0,
  },
  costData: { first_stage: '', last_stage: '', historic_data: [] },
  revenueData: [],
  spendData: [],
  leadVsApprovalData: [],
  trendData: [],
  facebookTrendData: [],
  facebookDailyData: [],
  facebookLeadCount: 0,
  tiktokTrendData: [],
  tiktokDailyData: [],
  tiktokLeadCount: 0,
  googleTrendData: [],
  googleDailyData: [],
  googleLeadCount: 0,
  paidSourceConfigList: [],
  filters: [],
  campaignData: [
    {
      id: '',
      name: 'No Data Found',
      indicators: {},
      metrics: {
        cpa: 0,
        cpc: 0,
        cpl: 0,
        cpm: 0,
        cpp: 0,
        ctr: 0,
        spend: 0,
        clicks: 0,
        paid_out: 0,
        impressions: 0,
      },
    },
  ],
  adsGroupsData: [
    {
      id: '',
      name: 'No Data Found',
      indicators: {},
      metrics: {
        cpa: 0,
        cpc: 0,
        cpl: 0,
        cpm: 0,
        cpp: 0,
        ctr: 0,
        spend: 0,
        clicks: 0,
        paid_out: 0,
        impressions: 0,
      },
    },
  ],
  adsData: [
    {
      id: '',
      name: 'No Data Found',
      indicators: {},
      metrics: {
        cpa: 0,
        cpc: 0,
        cpl: 0,
        cpm: 0,
        cpp: 0,
        ctr: 0,
        spend: 0,
        clicks: 0,
        paid_out: 0,
        impressions: 0,
      },
      preview_url: '',
    },
  ],
  keywordsData: [
    {
      id: '',
      name: 'No Data Found',
      indicators: {},
      metrics: {
        cpa: 0,
        cpc: 0,
        cpl: 0,
        cpm: 0,
        cpp: 0,
        ctr: 0,
        spend: 0,
        clicks: 0,
        paid_out: 0,
        impressions: 0,
      },
    },
  ],
  targetMetData: [],
  targetMetAverageData: [],
  paidSourceRevenueGraphData: [],
  revenue: 0,
  revenue_sales: 0,
  spend: 0,
  spend_sales: 0,
  paidSourceSpendGraphData: [],
  revenuePercentage: 0,
  spendPercentage: 0,
  campaignOverviewData: [],
  rollingAverageData: [],
  individualRating: [],
  stageNames: {
    first_stage: 'enquery',
    second_stage: 'stage2',
    third_stage: 'stage3',
  },
  teamRatingList: [],
  campaignOverviewLoading: false,
  unconfiguredOtherSourceList: { count: 0, items: [] },
  unconfiguredOtherSourceListLoading: false,
  otherSourceOverview: [],
  otherSourceRevenueGraphData: [],
  otherSourceRevenue: 0,
  otherSourceRevenuePercentage: 0,
  otherSourceRevenueSales: 0,
  targetMetError: null,
  TotalTrendsData: [],
  adsGroupLoading: false,
  revenueOverview: { historic_data: [], percentage: 0, revenue: 0 },
  spendOverview: { historic_data: [], percentage: 0, spend: 0 },
  filterSelected: localStorage.getItem(FILTER_SELECTED) || 'today',
  displayFilterSelected: localStorage.getItem(DISPLAY_FILTER) || 'Today',
  targetFilterSelected: localStorage.getItem(TARGET_FILTER_SELECTED) || 'Today',
  startDateRange:
    localStorage.getItem(START_DATE_RANGE) || moment().format('YYYY-MM-DD'),
  endDateRange:
    localStorage.getItem(END_DATE_RANGE) || moment().format('YYYY-MM-DD'),
}

// export const getFunnelPerformanceDashborad = createAsyncThunk(
//   'dashboard/getFunnelPerformanceDashboard',
//   async (request: funnelPerformanceQuerry, { rejectWithValue }) => {
//     const response = await dashboardService.getFunnelPerformance(request)
//     if (response.status === 200) {
//       return response
//     } else {
//       rejectWithValue(await response.json())
//       return response
//     }
//   },
// )

export const getFunnelPerformanceActivityDashborad = createAsyncThunk(
  'dashboard/getFunnelPerformanceActivityDashboard',
  async (request: funnelPerformanceQuerry, { rejectWithValue }) => {
    const response = await dashboardService.getFunnelPerformanceActivity(
      request,
    )
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getFunnelPerformanceProgressionalDashborad = createAsyncThunk(
  'dashboard/getFunnelPerformanceProgressionalDashborad',
  async (request: funnelPerformanceQuerry, { rejectWithValue }) => {
    const response = await dashboardService.getFunnelPerformanceProgressional(
      request,
    )
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getFunnelPerformanceMarketingActivity = createAsyncThunk(
  'dashboard/getFunnelPerformanceMarketingActivity',
  async (request: funnelPerformanceQuerry, { rejectWithValue }) => {
    const response = await dashboardService.getFunnelPerformanceActivity(
      request,
    )
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getFunnelPerformanceMarketingProgressional = createAsyncThunk(
  'dashboard/getFunnelPerformanceMarketingProgressional',
  async (request: funnelPerformanceQuerry, { rejectWithValue }) => {
    const response = await dashboardService.getFunnelPerformanceProgressional(
      request,
    )
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getFunnelPerformancePaidSourceActivity = createAsyncThunk(
  'dashboard/getFunnelPerformancePaidSourceActivity',
  async (request: funnelPerformanceQuerry, { rejectWithValue }) => {
    const response = await dashboardService.getFunnelPerformanceSourceActivity(
      request,
    )
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getFunnelPerformancePaidSourceProgressional = createAsyncThunk(
  'dashboard/getFunnelPerformancePaidSourceProgressional',
  async (request: funnelPerformanceQuerry, { rejectWithValue }) => {
    const response =
      await dashboardService.getFunnelPerformanceSourceProgressional(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getFunnelPerformanceOtherSources = createAsyncThunk(
  'dashboard/getFunnelPerformanceOtherSources',
  async (request: funnelPerformanceQuerry, { rejectWithValue }) => {
    const response = await dashboardService.getFunnelPerformance(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getRevenue = createAsyncThunk(
  'dashboard/getRevenue',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await dashboardService.getRevenue(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getCost = createAsyncThunk(
  'dashboard/getCost',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await dashboardService.getCost(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getCampaign = createAsyncThunk(
  'dashboard/getCampaign',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await dashboardService.getCampaign(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getAdGroups = createAsyncThunk(
  'dashboard/getAdsGroup',
  async (request: adGroupId, { rejectWithValue }) => {
    const response = await dashboardService.getAdGroups(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getAds = createAsyncThunk(
  'dashboard/getAds',
  async (request: adGroupId, { rejectWithValue }) => {
    const response = await dashboardService.getAds(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getKeywords = createAsyncThunk(
  'dashbaord/getKeywords',
  async (request: adGroupId, { rejectWithValue }) => {
    const response = await dashboardService.getKeywords(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getSpendData = createAsyncThunk(
  'dashboard/spendData',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await dashboardService.getSpendData(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getLeadVsApprovalData = createAsyncThunk(
  'dashboard/getLeadVsApprovalData',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await dashboardService.getLeadVsApprovalData(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

// export const getFacebookTrendData = createAsyncThunk(
//   'dashboard/getFacebookTrends',
//   async (request: graphQueryWithSource, { rejectWithValue }) => {
//     const response = await dashboardService.getTrendData(request)
//     if (response.status === 200) {
//       return response
//     } else {
//       rejectWithValue(await response.json())
//       return response
//     }
//   },
// )

// export const getTikTokTrendData = createAsyncThunk(
//   'dashboard/getTikTokTrends',
//   async (request: graphQueryWithSource, { rejectWithValue }) => {
//     const response = await dashboardService.getTrendData(request)
//     if (response.status === 200) {
//       return response
//     } else {
//       rejectWithValue(await response.json())
//       return response
//     }
//   },
// )

// export const getGoogleTrendData = createAsyncThunk(
//   'dashboard/getGoogleTrends',
//   async (request: graphQueryWithSource, { rejectWithValue }) => {
//     const response = await dashboardService.getTrendData(request)
//     if (response.status === 200) {
//       return response
//     } else {
//       rejectWithValue(await response.json())
//       return response
//     }
//   },
// )

export const getTotalTrendData = createAsyncThunk(
  'dashboard/getTotalTrends',
  async (request: graphQueryWithSource, { rejectWithValue }) => {
    const response = await dashboardService.getTrendData(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getTargetsMetData = createAsyncThunk(
  'dashboard/getTargetsMet',
  async (data: funnelQuerry, { rejectWithValue }) => {
    const response = await dashboardService.getTargetsMetData(data)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getTargetsMetSourceData = createAsyncThunk(
  'dashboard/getTargetsMetSource',
  async (data: funnelQuerry, { rejectWithValue }) => {
    const response = await dashboardService.getTargetsMetSourceData(data)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

// export const getTargetsMetData = createAsyncThunk(
//   'dashboard/getTargetsMet',
//   dashboardService.getTargetsMetData,
// )

export const getTargetsMetAverageData = createAsyncThunk(
  'dashboard/getTargetsMetAverage',
  async (data: funnelQuerry, { rejectWithValue }) => {
    const response = await dashboardService.getTargetsMetAverageData(data)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getPaidSourceConfigList = createAsyncThunk(
  'dashboard/getPaidSourceConfigList',
  async (comanyId: string, { rejectWithValue }) => {
    const response = await dashboardService.getPaidSourceConfigList(comanyId)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const togglePaidSource = createAsyncThunk(
  'dashboard/togglePaidSource',
  async (comanyId: string, { rejectWithValue }) => {
    const response = await dashboardService.togglePaidSource(comanyId)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getFilters = createAsyncThunk(
  'dashboard/getFilters',
  async (_, { rejectWithValue }) => {
    const response = await dashboardService.getFilters()
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getSourceRevenue = createAsyncThunk(
  'dashboard/getSourceRevenue',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await dashboardService.getSourceRevenue(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getSourceSpend = createAsyncThunk(
  'dashboard/getSourceSpend',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await dashboardService.getSourceSpend(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getCampaignOverview = createAsyncThunk(
  'dashboard/getCampaignOverview',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await dashboardService.getCampaignOverview(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getRollingAverages = createAsyncThunk(
  'dashboard/getRollingAverages',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await dashboardService.getRollingAverages(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getIndividualRatingData = createAsyncThunk(
  'dashboard/getIndividualRatingData',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await dashboardService.getIndividualRating(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getTeamRatingList = createAsyncThunk(
  'dashboard/getTeamRatingList',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await dashboardService.getTeamRatingList(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getUnconfiguredOtherSourceList = createAsyncThunk(
  'dashboard/getUnconfiguredOtherSourceList',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await dashboardService.getUnconfiguredOtherSourceList(
      request,
    )
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getOtherSourceOverview = createAsyncThunk(
  'dashboard/getOtherSourceOverview',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await dashboardService.getOtherSourceOverview(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getOtherSourceRevenue = createAsyncThunk(
  'dashboard/getOtherSourceRevenue',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await dashboardService.getOtherSourceRevenue(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getRevenueOverview = createAsyncThunk(
  'dashboard/getRevenueOverview',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await dashboardService.getOverviewRevenue(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getSpendOverview = createAsyncThunk(
  'dashboard/getSpendOverview',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await dashboardService.getOverviewSpend(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initState,
  reducers: {
    setSelectedFilter: (
      state,
      action: PayloadAction<{ filter: string; dispaly: string }>,
    ) => {
      localStorage.setItem(FILTER_SELECTED, action.payload.filter)
      localStorage.setItem(DISPLAY_FILTER, action.payload.dispaly)
      state.filterSelected = action.payload.filter
      state.displayFilterSelected = action.payload.dispaly
    },
    setTargetSelectedFilter: (state, action: PayloadAction<string>) => {
      localStorage.setItem(TARGET_FILTER_SELECTED, action.payload)
      state.targetFilterSelected = action.payload
    },
    setCustomDateRange: (
      state,
      action: PayloadAction<{ startDate: string; endDate: string }>,
    ) => {
      localStorage.setItem(START_DATE_RANGE, action.payload.startDate)
      localStorage.setItem(END_DATE_RANGE, action.payload.endDate)
      state.startDateRange = action.payload.startDate
      state.endDateRange = action.payload.endDate
    },
    reset: () => {
      return {
        ...initState,
        filterSelected: 'today',
        displayFilterSelected: 'Today',
        targetFilterSelected: 'Today',
        startDateRange: moment().format('YYYY-MM-DD'),
        endDateRange: moment().format('YYYY-MM-DD'),
      }
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getFunnelPerformanceActivityDashborad.pending, (state) => {
        state.funnelLoading = true
      })
      .addCase(
        getFunnelPerformanceActivityDashborad.fulfilled,
        (state, action) => {
          state.funnelLoading = false
          state.error = null
          state.funnelPerformanceDashboardData = action.payload.data
        },
      )
      .addCase(
        getFunnelPerformanceActivityDashborad.rejected,
        (state, action) => {
          state.funnelLoading = false
          state.funnelPerformanceDashboardData = {
            funnel_data: {},
            overall_conversion_time: '',
            prev_overall_conversion_time: '',
            revenue: 0,
          }
          state.error = action.payload
        },
      )
    builder
      .addCase(getFunnelPerformanceProgressionalDashborad.pending, (state) => {
        state.funnelLoading = true
      })
      .addCase(
        getFunnelPerformanceProgressionalDashborad.fulfilled,
        (state, action) => {
          state.funnelLoading = false
          state.error = null
          state.funnelPerformanceDashboardData = action.payload.data
        },
      )
      .addCase(
        getFunnelPerformanceProgressionalDashborad.rejected,
        (state, action) => {
          state.funnelLoading = false
          state.funnelPerformanceDashboardData = {
            funnel_data: {},
            overall_conversion_time: '',
            prev_overall_conversion_time: '',
            revenue: 0,
          }
          state.error = action.payload
        },
      )
    builder
      .addCase(getFunnelPerformanceMarketingActivity.pending, (state) => {
        state.funnelLoading = true
      })
      .addCase(
        getFunnelPerformanceMarketingActivity.fulfilled,
        (state, action) => {
          state.funnelLoading = false
          state.error = null
          state.funnelPerformanceMarketingData = action.payload.data
        },
      )
      .addCase(
        getFunnelPerformanceMarketingActivity.rejected,
        (state, action) => {
          state.funnelLoading = false
          state.funnelPerformanceMarketingData = {
            funnel_data: {},
            overall_conversion_time: '',
            prev_overall_conversion_time: '',
            revenue: 0,
          }
          state.error = action.payload
        },
      )
    builder
      .addCase(getFunnelPerformanceMarketingProgressional.pending, (state) => {
        state.funnelLoading = true
      })
      .addCase(
        getFunnelPerformanceMarketingProgressional.fulfilled,
        (state, action) => {
          state.funnelLoading = false
          state.error = null
          state.funnelPerformanceMarketingData = action.payload.data
        },
      )
      .addCase(
        getFunnelPerformanceMarketingProgressional.rejected,
        (state, action) => {
          state.funnelLoading = false
          state.funnelPerformanceMarketingData = {
            funnel_data: {},
            overall_conversion_time: '',
            prev_overall_conversion_time: '',
            revenue: 0,
          }
          state.error = action.payload
        },
      )
    builder
      .addCase(getFunnelPerformancePaidSourceActivity.pending, (state) => {
        state.funnelLoading = true
      })
      .addCase(
        getFunnelPerformancePaidSourceActivity.fulfilled,
        (state, action) => {
          state.funnelLoading = false
          state.error = null
          state.funnelPerformancePaidSourceData = action.payload.data
        },
      )
      .addCase(
        getFunnelPerformancePaidSourceActivity.rejected,
        (state, action) => {
          state.funnelLoading = false
          state.funnelPerformancePaidSourceData = {
            funnel_data: {},
            overall_conversion_time: '',
            prev_overall_conversion_time: '',
            revenue: 0,
          }
          state.error = action.payload
        },
      )
    builder
      .addCase(getFunnelPerformancePaidSourceProgressional.pending, (state) => {
        state.funnelLoading = true
      })
      .addCase(
        getFunnelPerformancePaidSourceProgressional.fulfilled,
        (state, action) => {
          state.funnelLoading = false
          state.error = null
          state.funnelPerformancePaidSourceData = action.payload.data
        },
      )
      .addCase(
        getFunnelPerformancePaidSourceProgressional.rejected,
        (state, action) => {
          state.funnelLoading = false
          state.funnelPerformancePaidSourceData = {
            funnel_data: {},
            overall_conversion_time: '',
            prev_overall_conversion_time: '',
            revenue: 0,
          }
          state.error = action.payload
        },
      )
    builder
      .addCase(getFunnelPerformanceOtherSources.pending, (state) => {
        state.funnelLoading = true
      })
      .addCase(getFunnelPerformanceOtherSources.fulfilled, (state, action) => {
        state.funnelLoading = false
        state.error = null
        state.funnelPerformanceOtherSourceData = action.payload.data
      })
      .addCase(getFunnelPerformanceOtherSources.rejected, (state, action) => {
        state.funnelLoading = false
        state.funnelPerformanceOtherSourceData = {
          funnel_data: {},
          overall_conversion_time: '',
          prev_overall_conversion_time: '',
          revenue: 0,
        }
        state.error = action.payload
      })
    builder
      .addCase(getRevenue.pending, (state) => {
        state.loading = true
      })
      .addCase(getRevenue.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.revenueData = action.payload.data
      })
      .addCase(getRevenue.rejected, (state, action) => {
        state.loading = false
        state.revenueData = []
        state.error = action.payload
      })
    builder
      .addCase(getCost.pending, (state) => {
        state.loading = true
      })
      .addCase(getCost.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.costData = action.payload.data
      })
      .addCase(getCost.rejected, (state, action) => {
        state.loading = false
        state.costData = { first_stage: '', last_stage: '', historic_data: [] }
        state.error = action.payload
      })
    builder
      .addCase(getCampaign.pending, (state) => {
        state.campaignLoading = true
      })
      .addCase(getCampaign.fulfilled, (state, action) => {
        state.campaignLoading = false
        state.error = null
        state.campaignData = action.payload.data
        state.stageNames = {
          first_stage: 'enquery',
          second_stage: 'stage2',
          third_stage: 'ptage3',
        }
      })
      .addCase(getCampaign.rejected, (state, action) => {
        state.campaignLoading = false
        state.campaignData = [
          {
            id: '',
            name: 'No Data Found',
            indicators: {},
            metrics: {
              cpa: 0,
              cpc: 0,
              cpl: 0,
              cpm: 0,
              cpp: 0,
              ctr: 0,
              spend: 0,
              clicks: 0,
              paid_out: 0,
              impressions: 0,
            },
          },
        ]
        state.error = action.payload
      })
    builder
      .addCase(getAdGroups.pending, (state) => {
        state.adsGroupLoading = true
        state.adsGroupsData = [
          {
            id: '',
            name: 'Fetching AdsetNames...',
            indicators: {},
            metrics: {
              cpa: 0,
              cpc: 0,
              cpl: 0,
              cpm: 0,
              cpp: 0,
              ctr: 0,
              spend: 0,
              clicks: 0,
              paid_out: 0,
              impressions: 0,
            },
          },
        ]
      })
      .addCase(getAdGroups.fulfilled, (state, action) => {
        state.adsGroupLoading = false
        state.error = null
        state.adsGroupsData = action.payload.data
      })
      .addCase(getAdGroups.rejected, (state, action) => {
        state.adsGroupLoading = false
        state.adsGroupsData = [
          {
            id: '',
            name: 'No Data Found',
            indicators: {},
            metrics: {
              cpa: 0,
              cpc: 0,
              cpl: 0,
              cpm: 0,
              cpp: 0,
              ctr: 0,
              spend: 0,
              clicks: 0,
              paid_out: 0,
              impressions: 0,
            },
          },
        ]
        state.error = action.payload
      })
    builder
      .addCase(getAds.pending, (state) => {
        state.loading = true
        state.adsData = [
          {
            id: '',
            name: 'Fetching AdNames...',
            indicators: {},
            metrics: {
              cpa: 0,
              cpc: 0,
              cpl: 0,
              cpm: 0,
              cpp: 0,
              ctr: 0,
              spend: 0,
              clicks: 0,
              paid_out: 0,
              impressions: 0,
            },
            preview_url: '',
          },
        ]
      })
      .addCase(getAds.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.adsData = action.payload.data
      })
      .addCase(getAds.rejected, (state, action) => {
        state.loading = false
        state.adsData = [
          {
            id: '',
            name: 'No Data Found',
            indicators: {},
            metrics: {
              cpa: 0,
              cpc: 0,
              cpl: 0,
              cpm: 0,
              cpp: 0,
              ctr: 0,
              spend: 0,
              clicks: 0,
              paid_out: 0,
              impressions: 0,
            },
            preview_url: '',
          },
        ]
        state.error = action.payload
      })
    builder
      .addCase(getKeywords.pending, (state) => {
        state.loading = true
        state.keywordsData = [
          {
            id: '',
            name: 'Fetching Keywords...',
            indicators: {},
            metrics: {
              cpa: 0,
              cpc: 0,
              cpl: 0,
              cpm: 0,
              cpp: 0,
              ctr: 0,
              spend: 0,
              clicks: 0,
              paid_out: 0,
              impressions: 0,
            },
          },
        ]
      })
      .addCase(getKeywords.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.keywordsData = action.payload.data
      })
      .addCase(getKeywords.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.keywordsData = [
          {
            id: '',
            name: 'No Data Found',
            indicators: {},
            metrics: {
              cpa: 0,
              cpc: 0,
              cpl: 0,
              cpm: 0,
              cpp: 0,
              ctr: 0,
              spend: 0,
              clicks: 0,
              paid_out: 0,
              impressions: 0,
            },
          },
        ]
      })
    builder
      .addCase(getSpendData.pending, (state) => {
        state.loading = true
      })
      .addCase(getSpendData.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.spendData = action.payload.data
      })
      .addCase(getSpendData.rejected, (state, action) => {
        state.loading = false
        state.spendData = []
        state.error = action.payload
      })
    builder
      .addCase(getLeadVsApprovalData.pending, (state) => {
        state.loading = true
      })
      .addCase(getLeadVsApprovalData.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.leadVsApprovalData = action.payload.data
      })
      .addCase(getLeadVsApprovalData.rejected, (state, action) => {
        state.loading = false
        state.leadVsApprovalData = []
        state.error = action.payload
      })
    // builder
    //   .addCase(getFacebookTrendData.pending, (state) => {
    //     state.loading = true
    //   })
    //   .addCase(getFacebookTrendData.fulfilled, (state, action) => {
    //     state.loading = false
    //     state.error = null
    //     state.facebookTrendData = action.payload.data.historic_data
    //     state.facebookDailyData = action.payload.data.daily
    //     state.facebookLeadCount = action.payload.data.leads
    //     action.payload.data
    //   })
    //   .addCase(getFacebookTrendData.rejected, (state, action) => {
    //     state.loading = false
    //     state.error = action.payload
    //   })
    // builder
    //   .addCase(getTikTokTrendData.pending, (state) => {
    //     state.loading = true
    //   })
    //   .addCase(getTikTokTrendData.fulfilled, (state, action) => {
    //     state.loading = false
    //     state.error = null
    //     state.tiktokTrendData = action.payload.data.historic_data
    //     state.tiktokDailyData = action.payload.data.daily
    //     state.tiktokLeadCount = action.payload.data.leads
    //     action.payload.data
    //   })
    //   .addCase(getTikTokTrendData.rejected, (state, action) => {
    //     state.loading = false
    //     state.error = action.payload
    //   })
    // builder
    //   .addCase(getGoogleTrendData.pending, (state) => {
    //     state.loading = true
    //   })
    //   .addCase(getGoogleTrendData.fulfilled, (state, action) => {
    //     state.loading = false
    //     state.error = null
    //     state.googleTrendData = action.payload.data.historic_data
    //     state.googleDailyData = action.payload.data.daily
    //     state.googleLeadCount = action.payload.data.leads
    //     action.payload.data
    //   })
    //   .addCase(getGoogleTrendData.rejected, (state, action) => {
    //     state.loading = false
    //     state.error = action.payload
    //   })
    builder
      .addCase(getTotalTrendData.pending, (state) => {
        state.loading = true
      })
      .addCase(getTotalTrendData.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.TotalTrendsData = action.payload.data
        action.payload.data
      })
      .addCase(getTotalTrendData.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.TotalTrendsData = []
      })
    builder
      .addCase(getTargetsMetData.pending, (state) => {
        state.loading = true
      })
      .addCase(getTargetsMetData.fulfilled, (state, action) => {
        state.loading = false
        state.targetMetData = action.payload.data
        state.targetMetError = null
      })
      .addCase(getTargetsMetData.rejected, (state) => {
        state.loading = false
        state.targetMetData = []
        state.targetMetError = 'noTargets'
      })
    builder
      .addCase(getTargetsMetSourceData.pending, (state) => {
        state.loading = true
      })
      .addCase(getTargetsMetSourceData.fulfilled, (state, action) => {
        state.loading = false
        state.targetMetData = action.payload.data
        state.targetMetError = null
      })
      .addCase(getTargetsMetSourceData.rejected, (state) => {
        state.loading = false
        state.targetMetData = []
        state.targetMetError = 'noTargets'
      })
    builder
      .addCase(getTargetsMetAverageData.pending, (state) => {
        state.loading = true
      })
      .addCase(getTargetsMetAverageData.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.targetMetAverageData = action.payload.data
      })
      .addCase(getTargetsMetAverageData.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
    builder
      .addCase(getPaidSourceConfigList.pending, (state) => {
        state.loading = true
      })
      .addCase(getPaidSourceConfigList.fulfilled, (state, action) => {
        state.loading = false
        state.paidSourceConfigList = action.payload.data
        state.error = null
      })
      .addCase(getPaidSourceConfigList.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
    builder
      .addCase(togglePaidSource.pending, (state) => {
        state.loading = true
      })
      .addCase(togglePaidSource.fulfilled, (state, action) => {
        state.loading = false
        state.paidSourceConfigList = state.paidSourceConfigList.map((source) =>
          source.id === action.payload.data.id
            ? { ...source, is_active: action.payload.data.is_active }
            : source,
        )
        state.error = null
      })
      .addCase(togglePaidSource.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
    builder
      .addCase(getFilters.pending, (state) => {
        state.loading = true
      })
      .addCase(getFilters.fulfilled, (state, action) => {
        state.loading = false
        state.filters = action.payload.data
        state.error = null
      })
      .addCase(getFilters.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
    builder
      .addCase(getSourceRevenue.pending, (state) => {
        state.loading = true
      })
      .addCase(getSourceRevenue.fulfilled, (state, action) => {
        state.loading = false
        state.paidSourceRevenueGraphData = action.payload.data.historic_data
        state.revenue = action.payload.data.revenue
        state.revenuePercentage = action.payload.data.percentage
        state.error = null
      })
      .addCase(getSourceRevenue.rejected, (state, action) => {
        state.loading = false
        state.paidSourceRevenueGraphData = []
        state.revenue = 0
        state.revenuePercentage = 0
        state.error = action.payload
      })
    builder
      .addCase(getSourceSpend.pending, (state) => {
        state.loading = true
      })
      .addCase(getSourceSpend.fulfilled, (state, action) => {
        state.loading = false
        state.paidSourceSpendGraphData = action.payload.data.historic_data
        state.spend = action.payload.data.spend
        state.spendPercentage = action.payload.data.percentage
        state.error = null
      })
      .addCase(getSourceSpend.rejected, (state, action) => {
        state.loading = false
        state.paidSourceSpendGraphData = []
        state.spend = 0
        state.spendPercentage = 0
        state.error = action.payload
      })
    builder
      .addCase(getCampaignOverview.pending, (state) => {
        state.loading = true
        state.campaignOverviewLoading = true
      })
      .addCase(getCampaignOverview.fulfilled, (state, action) => {
        state.loading = false
        state.campaignOverviewData = action.payload.data
        state.campaignOverviewLoading = false
        state.error = null
      })
      .addCase(getCampaignOverview.rejected, (state, action) => {
        state.loading = false
        state.campaignOverviewData = []
        state.error = action.payload
        state.campaignOverviewLoading = false
      })
    builder
      .addCase(getRollingAverages.pending, (state) => {
        state.loading = true
        state.campaignOverviewLoading = true
      })
      .addCase(getRollingAverages.fulfilled, (state, action) => {
        state.loading = false
        state.rollingAverageData = action.payload.data
        state.campaignOverviewLoading = false
        state.error = null
      })
      .addCase(getRollingAverages.rejected, (state, action) => {
        state.loading = false
        state.rollingAverageData = []
        state.error = action.payload
        state.campaignOverviewLoading = false
      })
    builder
      .addCase(getIndividualRatingData.pending, (state) => {
        state.loading = true
      })
      .addCase(getIndividualRatingData.fulfilled, (state, action) => {
        state.loading = false
        state.individualRating = action.payload.data
        state.error = null
      })
      .addCase(getIndividualRatingData.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.individualRating = []
      })
    builder
      .addCase(getTeamRatingList.pending, (state) => {
        state.loading = true
      })
      .addCase(getTeamRatingList.fulfilled, (state, action) => {
        state.loading = false
        state.teamRatingList = action.payload.data
        state.error = null
      })
      .addCase(getTeamRatingList.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.teamRatingList = []
      })
    builder
      .addCase(getUnconfiguredOtherSourceList.pending, (state) => {
        state.unconfiguredOtherSourceListLoading = true
      })
      .addCase(getUnconfiguredOtherSourceList.fulfilled, (state, action) => {
        state.unconfiguredOtherSourceListLoading = false
        state.unconfiguredOtherSourceList = action.payload.data
        state.error = null
      })
      .addCase(getUnconfiguredOtherSourceList.rejected, (state, action) => {
        state.unconfiguredOtherSourceListLoading = false
        state.error = action.payload
        state.unconfiguredOtherSourceList = { count: 0, items: [] }
      })
    builder
      .addCase(getOtherSourceOverview.pending, (state) => {
        state.loading = true
      })
      .addCase(getOtherSourceOverview.fulfilled, (state, action) => {
        state.loading = false
        state.otherSourceOverview = action.payload.data
        state.error = null
      })
      .addCase(getOtherSourceOverview.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.otherSourceOverview = []
      })
    builder
      .addCase(getOtherSourceRevenue.pending, (state) => {
        state.loading = true
      })
      .addCase(getOtherSourceRevenue.fulfilled, (state, action) => {
        state.loading = false
        state.otherSourceRevenueGraphData = action.payload.data.historic_data
        state.otherSourceRevenue = action.payload.data.revenue
        state.otherSourceRevenueSales = action.payload.data.revenue_sales
        state.otherSourceRevenuePercentage = action.payload.data.percentage
        state.error = null
      })
      .addCase(getOtherSourceRevenue.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.otherSourceRevenueGraphData = []
        state.otherSourceRevenue = 0
        state.otherSourceRevenueSales = 0
        state.otherSourceRevenuePercentage = 0
      })
    builder
      .addCase(getRevenueOverview.pending, (state) => {
        state.loading = true
      })
      .addCase(getRevenueOverview.fulfilled, (state, action) => {
        state.loading = false
        state.revenueOverview = action.payload.data
        state.error = null
      })
      .addCase(getRevenueOverview.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.revenueOverview = { historic_data: [], percentage: 0, revenue: 0 }
      })
    builder
      .addCase(getSpendOverview.pending, (state) => {
        state.loading = true
      })
      .addCase(getSpendOverview.fulfilled, (state, action) => {
        state.loading = false
        state.spendOverview = action.payload.data
        state.error = null
      })
      .addCase(getSpendOverview.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.spendOverview = { historic_data: [], percentage: 0, spend: 0 }
      })
  },
})

export const {
  setSelectedFilter,
  setTargetSelectedFilter,
  setCustomDateRange,
  reset,
} = dashboardSlice.actions

export default dashboardSlice.reducer
