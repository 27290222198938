import httpClient from 'http/httpClient'
import {
  WallboardService as IWallboardService,
  WallboardData,
  WallboardUrlRes,
} from 'types/wallboards.model'

const WallboardService = (): IWallboardService => {
  return {
    getWallboardUrl: (companyId: string): HttpPromise<WallboardUrlRes> => {
      return httpClient.get(`/api/dashboard/wallboards/${companyId}/list/`)
    },
    getWallboardData: (url: string): HttpPromise<WallboardData> => {
      return httpClient.get(`${url}`)
    },
  }
}

export default WallboardService()
