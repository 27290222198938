import httpClient from 'http/httpClient'
import {
  AdsData,
  CampaignData,
  DashboardTeamList,
  DashboardService as IDashboardService,
  IndividualRating,
  OtherSourceList,
  OtherSourceOverview,
  PaidSourceConfig,
  PaidSourceRevenue,
  PaidSourceSpend,
  TargetMetData,
  adGroupId,
  campaignOverview,
  costData,
  filters,
  funnelPerformance,
  funnelPerformanceQuerry,
  funnelQuerry,
  graphQuery,
  graphQueryWithSource,
  revenueData,
  spendData,
  trendData,
  RevenueOverviewResponse,
  SpendOverviewResponse,
  rollingAverageData,
} from 'types/dashboard.model'

const DashboardService = (): IDashboardService => {
  return {
    getFunnelPerformance: (
      request: funnelPerformanceQuerry,
    ): HttpPromise<funnelPerformance[]> => {
      return httpClient.get(
        `/api/dashboard/overview/${request.id}/funnel-performance/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }${request.source ? '&source=' + request.source : ''}&type_of_data=${
          request.type ? 'progression' : 'activity'
        }`,
      )
    },
    getFunnelPerformanceActivity: (
      request: funnelPerformanceQuerry,
    ): HttpPromise<funnelPerformance[]> => {
      return httpClient.get(
        `/api/dashboard/overview/${request.id}/funnel-performance-activity/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }${request.source ? '&source=' + request.source : ''}&type_of_data=${
          request.type ? 'progression' : 'activity'
        }`,
      )
    },
    getFunnelPerformanceProgressional: (
      request: funnelPerformanceQuerry,
    ): HttpPromise<funnelPerformance[]> => {
      return httpClient.get(
        `/api/dashboard/overview/${
          request.id
        }/funnel-performance-progressional/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }${request.source ? '&source=' + request.source : ''}&type_of_data=${
          request.type ? 'progression' : 'activity'
        }`,
      )
    },
    getFunnelPerformanceSourceActivity: (
      request: funnelPerformanceQuerry,
    ): HttpPromise<funnelPerformance[]> => {
      return httpClient.get(
        `/api/dashboard/overview/${
          request.id
        }/funnel-performance-source-activity/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }${request.source ? '&source=' + request.source : ''}&type_of_data=${
          request.type ? 'progression' : 'activity'
        }`,
      )
    },
    getFunnelPerformanceSourceProgressional: (
      request: funnelPerformanceQuerry,
    ): HttpPromise<funnelPerformance[]> => {
      return httpClient.get(
        `/api/dashboard/overview/${
          request.id
        }/funnel-performance-source-progressional/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }${request.source ? '&source=' + request.source : ''}&type_of_data=${
          request.type ? 'progression' : 'activity'
        }`,
      )
    },
    getRevenue: (request: graphQuery): HttpPromise<revenueData[]> => {
      return httpClient.get(
        `/api/dashboard/overview/${request.id}/revenue/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getCost: (request: graphQuery): HttpPromise<costData[]> => {
      return httpClient.get(
        `/api/dashboard/overview/${request.id}/costs/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getCampaign: (request: graphQuery): HttpPromise<CampaignData[]> => {
      return httpClient.get(
        `/api/dashboard/marketing/${request.id}/campaigns/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getAdGroups: (request: adGroupId): HttpPromise<CampaignData[]> => {
      return httpClient.get(
        `/api/dashboard/marketing/${request.paidSourceConfigId}/ad-groups/${
          request.campaignId
        }/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getAds: (request: adGroupId): HttpPromise<AdsData[]> => {
      return httpClient.get(
        `/api/dashboard/marketing/${request.paidSourceConfigId}/ads/${
          request.campaignId
        }/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getKeywords: (request: adGroupId): HttpPromise<CampaignData[]> => {
      return httpClient.get(
        `/api/dashboard/marketing/${request.paidSourceConfigId}/keywords/${
          request.campaignId
        }/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getSpendData: (request: graphQuery): HttpPromise<spendData[]> => {
      return httpClient.get(
        `/api/dashboard/overview/${request.id}/spend-comparison/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getLeadVsApprovalData: (request: graphQuery): HttpPromise<spendData[]> => {
      return httpClient.get(
        `/api/dashboard/overview/${request.id}/stage-comparison/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }
    `,
      )
    },
    getTrendData: (request: graphQueryWithSource): HttpPromise<trendData[]> => {
      return httpClient.get(
        `/api/dashboard/overview/${request.id}/trends/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getTargetsMetData: (
      request: funnelQuerry,
    ): HttpPromise<TargetMetData[]> => {
      return httpClient.get(
        `/api/dashboard/overview/${request.id}/targets/?filter=${request.time}${
          request.exclude ? '&exclude=true' : '&exclude=false'
        }${request.source ? '&source=' + request.source : ''}`,
      )
    },
    getTargetsMetSourceData: (
      request: funnelQuerry,
    ): HttpPromise<TargetMetData[]> => {
      return httpClient.get(
        `/api/dashboard/overview/${request.id}/source-targets/?filter=${
          request.time
        }${request.exclude ? '&exclude=true' : '&exclude=false'}${
          request.source ? '&source=' + request.source : ''
        }`,
      )
    },
    getTargetsMetAverageData: (
      request: funnelQuerry,
    ): HttpPromise<TargetMetData[]> => {
      return httpClient.get(
        `/api/dashboard/overview/${request.id}/targets-averages/?filter=${
          request.time
        }${request.exclude ? '&exclude=true' : '&exclude=false'}${
          request.source ? '&source=' + request.source : ''
        }`,
      )
    },
    getPaidSourceConfigList: (
      comanyId: string,
    ): HttpPromise<PaidSourceConfig[]> => {
      return httpClient.get(`/api/marketing/${comanyId}/market-source-config/`)
    },
    togglePaidSource: (companyId: string): HttpPromise<PaidSourceConfig> => {
      return httpClient.put(
        `/api/marketing/paid_source/${companyId}/toggle-active/`,
      )
    },
    getFilters: (): HttpPromise<filters[]> => {
      return httpClient.get(`/api/dashboard/filters/`)
    },
    getSourceRevenue: (request: graphQuery): HttpPromise<PaidSourceRevenue> => {
      return httpClient.get(
        `/api/dashboard/marketing/${request.id}/source-revenue/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getSourceSpend: (request: graphQuery): HttpPromise<PaidSourceSpend> => {
      return httpClient.get(
        `/api/dashboard/marketing/${request.id}/source-spend/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getCampaignOverview: (
      request: graphQuery,
    ): HttpPromise<campaignOverview[]> => {
      return httpClient.get(
        `/api/dashboard/overview/${request.id}/source-overview/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getRollingAverages: (
      request: graphQuery,
    ): HttpPromise<rollingAverageData[]> => {
      return httpClient.get(
        `/api/dashboard/source-overview/${request.id}/rolling-average/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getIndividualRating: (
      request: graphQuery,
    ): HttpPromise<IndividualRating[]> => {
      return httpClient.get(
        `/api/sales/overview/${request.id}/sales-person-list/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getTeamRatingList: (
      request: graphQuery,
    ): HttpPromise<DashboardTeamList[]> => {
      return httpClient.get(
        `/api/sales/team-management/${request.id}/team-list-dashboard/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getUnconfiguredOtherSourceList: (
      request: graphQuery,
    ): HttpPromise<OtherSourceList[]> => {
      return httpClient.get(
        `/api/dashboard/overview/${request.id}/sources-list/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getOtherSourceOverview: (
      request: graphQuery,
    ): HttpPromise<OtherSourceOverview[]> => {
      return httpClient.get(
        `/api/dashboard/overview/${request.id}/other-source-overview/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getOtherSourceRevenue: (
      request: graphQuery,
    ): HttpPromise<PaidSourceRevenue[]> => {
      return httpClient.get(
        `/api/marketing/unmapped_source/${request.id}/source-revenue/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getOverviewRevenue: (
      request: graphQuery,
    ): HttpPromise<RevenueOverviewResponse> => {
      return httpClient.get(
        `/api/dashboard/source-overview/${request.id}/source-revenue/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getOverviewSpend: (
      request: graphQuery,
    ): HttpPromise<SpendOverviewResponse> => {
      return httpClient.get(
        `/api/dashboard/source-overview/${request.id}/source-spend/?${
          request.time === 'custom'
            ? `start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
  }
}

export default DashboardService()
