import httpClient from 'http/httpClient'
import {
  EventBreakdownResponse,
  EventFailureResponse,
  DebuggingService as IDebuggingService,
  SourceResponse,
  SummaryResponse,
  TrendSummaryResponse,
  LiveEvent,
} from 'types/debugging.model'
import { graphQuery } from 'types/dashboard.model'

const DebuggingService = (): IDebuggingService => {
  return {
    getSummary: (request: graphQuery): HttpPromise<SummaryResponse> => {
      return httpClient.get(
        `/api/funnels/debugging/summary/?company_id=${request.id}&${
          request.time === 'custom'
            ? `filter=custom&start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getTrendSummary: (
      request: graphQuery,
    ): HttpPromise<TrendSummaryResponse> => {
      return httpClient.get(
        `/api/funnels/debugging/trend-summary/?company_id=${request.id}&${
          request.time === 'custom'
            ? `filter=custom&start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getEventBreakdown: (
      request: graphQuery,
    ): HttpPromise<EventBreakdownResponse> => {
      return httpClient.get(
        `/api/funnels/debugging/event-breakdown/?company_id=${request.id}&${
          request.time === 'custom'
            ? `filter=custom&start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getEventFailure: (
      request: graphQuery,
    ): HttpPromise<EventFailureResponse> => {
      return httpClient.get(
        `/api/funnels/debugging/event-failure/?company_id=${request.id}&${
          request.time === 'custom'
            ? `filter=custom&start_date=${request.startDate}&end_date=${request.endDate}`
            : `filter=${request.time}`
        }`,
      )
    },
    getSources: (companyId: string): HttpPromise<SourceResponse> => {
      return httpClient.get(
        `/api/funnels/debugging/event-source/?company_id=${companyId}`,
      )
    },
    getLiveEvents: (companyId: string): HttpPromise<LiveEvent[]> => {
      return httpClient.get(
        `/api/funnels/debugging/live-debugging/?company_id=${companyId}`,
      )
    },
  }
}

export default DebuggingService()
