import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import userService from 'store/services/userService'
import {
  GetUserInfo,
  Role,
  Permission,
  EditUserBody,
  ToggleUserBody,
  ResendInviteBody,
  TeamList,
  updateTeamRequest,
  TeamRequest,
} from 'types/users.model'

export interface AppSlice {
  loading: boolean
  error: any
  currentTab: string
  user: GetUserInfo | null
  users: GetUserInfo[]
  roles: Role[]
  permissions: Permission[]
  teamList: TeamList[]
  teamMembers: GetUserInfo[]
  userLoading: boolean
  teamMembersLoading: boolean
}

const initState: AppSlice = {
  loading: false,
  error: null,
  currentTab: 'Users',
  user: null,
  users: [],
  roles: [],
  permissions: [],
  teamList: [],
  teamMembers: [],
  userLoading: false,
  teamMembersLoading: false,
}

export const postUser = createAsyncThunk('user/postUser', userService.postUser)

export const getUser = createAsyncThunk(
  'user/getUser',
  async (comanyId: string, { rejectWithValue }) => {
    const response = await userService.getUser(comanyId)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const fetchRoles = createAsyncThunk(
  'user/fetchRoles',
  async (_, { rejectWithValue }) => {
    const response = await userService.getRole()
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const fetchPermissions = createAsyncThunk(
  'user/fetchPermissions',
  async (_, { rejectWithValue }) => {
    const response = await userService.getPermission()
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const deleteUser = createAsyncThunk(
  'user/deleteUser',
  async (userId: string) => {
    const response = await userService.deleteUser(userId)
    return response
  },
)

export const editUser = createAsyncThunk(
  'user/editUser',
  async ({
    userId,
    userData,
  }: {
    userId: string | undefined
    userData: EditUserBody
  }) => {
    const response = await userService.editUser(userId, userData)
    return response
  },
)

export const toggleUser = createAsyncThunk(
  'user/toggleUser',
  async ({
    userId,
    toggleData,
  }: {
    userId: string
    toggleData: ToggleUserBody
  }) => {
    const response = await userService.toggleUser(userId, toggleData)
    return response
  },
)

export const resendInvite = createAsyncThunk(
  'user/resendInvite',
  async ({
    userId,
    resendData,
  }: {
    userId: string
    resendData: ResendInviteBody
  }) => {
    const response = await userService.resendInvite(userId, resendData)
    return response
  },
)

export const convertUserByInvite = createAsyncThunk(
  'user/convertUserByInvite',
  userService.convertUserByInvite,
)

export const inviteSetupUser = createAsyncThunk(
  'user/inviteSetupUser',
  userService.inviteSetupUser,
)

export const getTeamList = createAsyncThunk(
  'user/getTeamList',
  async (companyId: string, { rejectWithValue }) => {
    const response = await userService.getTeamList(companyId)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getUsersOfTeam = createAsyncThunk(
  'user/getUsersOfTeam',
  async (req: TeamRequest, { rejectWithValue }) => {
    const response = await userService.getUsersOfTeam(req)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const updateTeam = createAsyncThunk(
  'user/updateTeam',
  async (request: updateTeamRequest, { rejectWithValue }) => {
    const response = await userService.updateTeam(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const createTeam = createAsyncThunk(
  'user/createTeam',
  userService.createTeam,
)

const userSlice = createSlice({
  name: 'user',
  initialState: initState,
  reducers: {
    setCurrentTab: (state, action: PayloadAction<string>) => {
      state.currentTab = action.payload
    },
    reset: () => {
      return initState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postUser.pending, (state) => {
        state.loading = true
      })
      .addCase(postUser.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(postUser.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
    builder
      .addCase(getUser.pending, (state) => {
        state.loading = true
        state.userLoading = true
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.users = action.payload.data
        state.userLoading = false
      })
      .addCase(getUser.rejected, (state, action) => {
        state.loading = false
        state.user = null
        state.error = action.payload
        state.userLoading = false
      })
    builder
      .addCase(fetchRoles.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.loading = false
        state.roles = action.payload.data
        state.error = null
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.loading = false
        state.roles = []
        state.error = action.payload
      })
    builder
      .addCase(fetchPermissions.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchPermissions.fulfilled, (state, action) => {
        state.loading = false
        state.permissions = action.payload.data
        state.error = null
      })
      .addCase(fetchPermissions.rejected, (state, action) => {
        state.loading = false
        state.permissions = []
        state.error = action.payload
      })
    builder
      .addCase(deleteUser.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
    builder
      .addCase(editUser.pending, (state) => {
        state.loading = true
      })
      .addCase(editUser.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(editUser.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
    builder
      .addCase(toggleUser.pending, (state) => {
        state.loading = true
      })
      .addCase(toggleUser.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(toggleUser.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
    builder
      .addCase(resendInvite.pending, (state) => {
        state.loading = true
      })
      .addCase(resendInvite.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(resendInvite.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
    builder
      .addCase(convertUserByInvite.pending, (state) => {
        state.loading = true
      })
      .addCase(convertUserByInvite.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(convertUserByInvite.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
    builder
      .addCase(getTeamList.pending, (state) => {
        state.loading = true
      })
      .addCase(getTeamList.fulfilled, (state, action) => {
        state.loading = false
        state.teamList = action.payload.data
        state.error = null
      })
      .addCase(getTeamList.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
    builder
      .addCase(createTeam.pending, (state) => {
        state.loading = true
      })
      .addCase(createTeam.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(createTeam.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
    builder
      .addCase(updateTeam.pending, (state) => {
        state.loading = true
      })
      .addCase(updateTeam.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(updateTeam.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
    builder
      .addCase(getUsersOfTeam.pending, (state) => {
        state.teamMembersLoading = true
        state.teamMembers = []
      })
      .addCase(getUsersOfTeam.fulfilled, (state, action) => {
        state.teamMembersLoading = false
        state.teamMembers = action.payload.data
        state.error = null
      })
      .addCase(getUsersOfTeam.rejected, (state, action) => {
        state.teamMembersLoading = false
        state.teamMembers = []
        state.error = action.payload
      })
  },
})

export const { reset, setCurrentTab } = userSlice.actions
export default userSlice.reducer
