import httpClient from 'http/httpClient'
import {
  ProfileDetails,
  ChnagePasswordRequest,
  ProfileService as IProfileService,
  UpdateProfileFormData,
  UserDetails,
  companyDetails,
  companyDetailsReq,
} from 'types/profile.model'

const ProfileService = (): IProfileService => {
  return {
    getProfileDetails: (): HttpPromise<ProfileDetails> => {
      return httpClient.get('/api/user/profile/')
    },
    getUserDetails: (): HttpPromise<UserDetails> => {
      return httpClient.get('/api/users/details/')
    },
    updateProfile: (
      request: UpdateProfileFormData,
    ): HttpPromise<ProfileDetails> => {
      return httpClient.put('/api/user/profile/', { data: request })
    },
    changePassword: (
      request: ChnagePasswordRequest,
    ): HttpPromise<ProfileDetails> => {
      return httpClient.post('/api/auth/password-change/', {
        data: request,
      })
    },
    getCompanyDetails: (companyId: string): HttpPromise<companyDetails> => {
      return httpClient.get(`api/agency/company/${companyId}/details/`)
    },
    putCompanyDetails: (
      req: companyDetailsReq,
    ): HttpPromise<companyDetails> => {
      return httpClient.put(`/api/agency/company/${req.companyId}/`, {
        data: req.data,
      })
    },
  }
}

export default ProfileService()
