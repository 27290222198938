import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import profileService from 'store/services/profileService'
import {
  ProfileDetails,
  SubscriptionPlanConstraints,
  companyDetails,
} from 'types/profile.model'

export interface ProfileSlice {
  profile: ProfileDetails
  isAdmin: boolean
  companyId: string
  subscriptionStatus: boolean | 'not-found'
  subscriptionValid: boolean | 'not-found'
  subscriptionPlanConstraints: SubscriptionPlanConstraints
  permissions: string[]
  companyInfo: companyDetails
  role: number
  loading: boolean
  error: any
}

const initState: ProfileSlice = {
  profile: {
    id: 0,
    name: '',
    email: '',
    profile_pic: '',
    company_name: '',
    sales_person_id: '',
    sales_team_id: '',
  },
  isAdmin: false,
  companyId: '',
  subscriptionStatus: false,
  subscriptionValid: false || 'not-found',
  subscriptionPlanConstraints: {
    id: '',
    created_at: '',
    updated_at: '',
    subscription_id: '',
    marketing_spend: 0,
    marketing_sources: 0,
    limit_marketing_sources: false,
    mobile_dashboard: false,
    sales_funnel: 0,
    limit_sales_funnel: false,
    ai_data_analyst: false,
    sales_login: false,
    notification_alert: false,
    custom_dashboard: false,
    customer_success_manager: false,
    forecasting: false,
    demographic_and_sales_insights: false,
    white_label: false,
    wallboards: false,
    sales_seat_number: 0,
  },
  permissions: [],
  role: 0,
  companyInfo: {
    name: '',
    size: '',
    website: '',
    agency: '',
    logo: '',
    id: '',
    no_of_users: 0,
  },
  loading: false,
  error: null,
}

export const getProfileDetails = createAsyncThunk(
  'profile/getProfileDetails',
  profileService.getProfileDetails,
)

export const updateProfile = createAsyncThunk(
  'profile/updateProfile',
  profileService.updateProfile,
)

export const changePassword = createAsyncThunk(
  'profile/changePassword',
  profileService.changePassword,
)

export const getUserDetails = createAsyncThunk(
  'users/details',
  profileService.getUserDetails,
)

export const getCompanyDetails = createAsyncThunk(
  'users/getCompanyDetails',
  async (companyId: string, { rejectWithValue }) => {
    const response = await profileService.getCompanyDetails(companyId)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const putCompanyDetails = createAsyncThunk(
  'users/putCompanyDetails',
  profileService.putCompanyDetails,
)

const profileSlice = createSlice({
  name: 'profile',
  initialState: initState,
  reducers: {
    reset: () => {
      return initState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileDetails.pending, (state) => {
        state.loading = true
      })
      .addCase(getProfileDetails.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.profile = action.payload.data
      })
      .addCase(getProfileDetails.rejected, (state, action) => {
        state.loading = false
        state.profile = {
          name: '',
          email: '',
          profile_pic: '',
          id: 0,
          company_name: '',
          sales_person_id: '',
          sales_team_id: '',
        }
        state.error = action.payload
      }),
      builder
        .addCase(getUserDetails.pending, (state) => {
          state.loading = true
        })
        .addCase(getUserDetails.fulfilled, (state, action) => {
          state.loading = false
          state.error = null
          state.isAdmin = action.payload.data.is_admin
          state.role = action.payload.data.role
          state.companyId = action.payload.data.company_id
          state.subscriptionPlanConstraints =
            action.payload.data.subscription_plan_constraints
          state.subscriptionStatus = action.payload.data.subscription_status
          state.subscriptionValid = action.payload.data.subscription_valid
          state.permissions = action.payload.data.permissions
        })
        .addCase(getUserDetails.rejected, (state, action) => {
          state.loading = false
          state.isAdmin = false
          state.companyId = ''
          state.error = action.payload
          state.permissions = []
        })
    builder
      .addCase(getCompanyDetails.pending, (state) => {
        state.loading = true
      })
      .addCase(getCompanyDetails.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.companyInfo = action.payload.data
      })
      .addCase(getCompanyDetails.rejected, (state, action) => {
        state.loading = false
        state.companyInfo = {
          name: '',
          size: '',
          website: '',
          agency: '',
          logo: '',
          id: '',
          no_of_users: 0,
        }
        state.error = action.payload
      })
  },
})

export const { reset } = profileSlice.actions
export default profileSlice.reducer
