import React, { createContext, useContext, useState } from 'react'

interface TabViewContextType {
  tabView: string
  setTabView: React.Dispatch<React.SetStateAction<string>>
}

const TabViewContext = createContext<TabViewContextType | null>(null)

interface TabViewProviderProps {
  children: React.ReactNode
}

export const TabViewProvider: React.FC<TabViewProviderProps> = React.memo(
  ({ children }) => {
    const defaultTabView = 'Progressional'
    const [tabView, setTabView] = useState<string>(defaultTabView)

    return (
      <TabViewContext.Provider value={{ tabView, setTabView }}>
        {children || <div>No children passed to TabViewProvider!</div>}
      </TabViewContext.Provider>
    )
  },
)

export const useTabViewContext = () => {
  const context = useContext(TabViewContext)

  if (!context) {
    throw new Error('useTabViewContext must be used within a TabViewProvider')
  }
  return context
}
