import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import subscriptionService from 'store/services/subscriptionService'
import {
  SubscriptionsDetails,
  PaymentSuccessRequest,
  SubscriptionInfo,
} from 'types/subscriptions.modal'

export interface SubscriptionInitState {
  loading: boolean
  error: any
  subscriptionDetails: SubscriptionsDetails[]
  subscriptionInfo: SubscriptionInfo
}

const initState: SubscriptionInitState = {
  subscriptionDetails: [],
  subscriptionInfo: {
    name: '',
    active: false,
    start_date: '',
    end_date: '',
    amount: 0,
    is_valid: true,
    custom_price: false,
    plan_constraints: {
      marketing_spend: 0,
      marketing_sources: 2,
      limit_marketing_sources: true,
      mobile_dashboard: true,
      sales_funnel: 1,
      limit_sales_funnel: true,
      ai_data_analyst: false,
      sales_login: false,
      notification_alert: false,
      custom_dashboard: false,
      customer_success_manager: false,
      forecasting_powered_by_pruuv_ai: false,
      demographic_and_sales_performance_insights: false,
      white_label: false,
      wallboards: false,
      sales_seat_number: 0,
      sdk_enabled: false,
    },
  },
  loading: false,
  error: null,
}

export const getSubscriptionDetails = createAsyncThunk(
  'dashboard/getSubscriptionDetails',
  async (companyId: string, { rejectWithValue }) => {
    const response = await subscriptionService.getSubscriptionDetails(companyId)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getSubscriptionInfo = createAsyncThunk(
  'dashboard/getSubscriptionInfo',
  async (companyId: string, { rejectWithValue }) => {
    const response = await subscriptionService.getSubscriptionInfo(companyId)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const subscriptionCheckout = createAsyncThunk(
  'dashboard/subscriptionCheckout',
  subscriptionService.postSubscription,
)

export const updateSubscription = createAsyncThunk(
  'dashboard/subscriptionCheckout',
  subscriptionService.updateSubscription,
)

export const getPaymentSuccess = createAsyncThunk(
  'dashboard/getPaymentSuccess',
  async (req: PaymentSuccessRequest, { rejectWithValue }) => {
    const response = await subscriptionService.paymentSuccess(req)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const cancelSubscription = createAsyncThunk(
  'dashboard/cancelSubscription',
  subscriptionService.cancelSubscription,
)

export const createCustomPlan = createAsyncThunk(
  'dashboard/createCustomPlan',
  subscriptionService.createCustomPlan,
)

const subscriptionSlice = createSlice({
  name: 'subscriptions',
  initialState: initState,
  reducers: {
    reset: () => {
      return initState
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getSubscriptionDetails.pending, (state) => {
        state.loading = true
      })
      .addCase(getSubscriptionDetails.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.subscriptionDetails = action.payload.data
      })
      .addCase(getSubscriptionDetails.rejected, (state, action) => {
        state.loading = false
        state.subscriptionDetails = []
        state.error = action.payload
      })
    builder
      .addCase(getSubscriptionInfo.pending, (state) => {
        state.loading = true
      })
      .addCase(getSubscriptionInfo.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.subscriptionInfo = action.payload.data
      })
      .addCase(getSubscriptionInfo.rejected, (state, action) => {
        state.loading = false
        state.subscriptionInfo = {
          name: '',
          active: false,
          start_date: '',
          end_date: '',
          amount: 0,
          is_valid: false,
          custom_price: false,
          plan_constraints: {
            marketing_spend: 0,
            marketing_sources: 2,
            limit_marketing_sources: true,
            mobile_dashboard: true,
            sales_funnel: 1,
            limit_sales_funnel: true,
            ai_data_analyst: false,
            sales_login: false,
            notification_alert: false,
            custom_dashboard: false,
            customer_success_manager: false,
            forecasting_powered_by_pruuv_ai: false,
            demographic_and_sales_performance_insights: false,
            white_label: false,
            wallboards: false,
            sales_seat_number: 0,
            sdk_enabled: false,
          },
        }
        state.error = action.payload
      })
  },
})

export const { reset } = subscriptionSlice.actions

export default subscriptionSlice.reducer
