interface Paths {
  [key: string]: string
}

export default {
  REGISTER: '/',
  FORGOTPASSWORD: '/forgotPassword',
  WORKSPACE: '/workspace',
  HOME: '/home/*',
  LOGIN: '/login',
  VERIFYEMAIL: '/verifyemail',
  SETPASSWORD: '/setpassword',
  PASSWORDEMAIL: '/sendemail',
  INVITEUSERSIGNUP: '/inviteusersignup',
  PAYMENTSUCCESS: '/:companyId/paymentsuccess',
  PAYMENTFAILED: '/:companyId/paymentcancel',
  SUBSCRIPTIONDETAILS: '/subscriptiondetails/:name',
  WALLBOARDSHARE: '/wallboard-share/:url/:funnelId',
  DEBUG: '/debug',
  DEBUG2: '/debug2',
  NOT_FOUND: '*',
  AGENCY: '/agency',
} as Paths
