import { configureStore } from '@reduxjs/toolkit'
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux'

import profileReducer from 'store/slices/profileSlice'
import authReducer from 'store/slices/authSlice'
import userReducer from 'store/slices/userSlice'
import workspaceReducer from 'store/slices/workspaceSlice'
import dashboardReducer from 'store/slices/dashboardSlice'
import salesReducer from 'store/slices/salesSlice'
import subscriptionReducer from 'store/slices/subscriptionSlice'
import reportingReducer from 'store/slices/reportingSlice'
import targetReducer from 'store/slices/targetSlice'
import wallboardReducer from 'store/slices/wallboardSlice'
import debuggingReducer from 'store/slices/debuggingSlice'
import adsCrmDataReducer from 'store/slices/adsCrmDataSlice'
import crmDataReducer from './slices/crmRevenueBreakDownSlice';
import { debugMiddleware } from './middleware/debugMiddleware'
// TODO: setup deeping caching middleware when data from API becomes large
// import { cacheMiddleware } from './middleware/cacheMiddleware'

const store = configureStore({
  reducer: {
    profile: profileReducer,
    auth: authReducer,
    user: userReducer,
    workspace: workspaceReducer,
    dashboard: dashboardReducer,
    sales: salesReducer,
    subscription: subscriptionReducer,
    reporting: reportingReducer,
    target: targetReducer,
    wallboard: wallboardReducer,
    debugging: debuggingReducer,
    adsCrm: adsCrmDataReducer,
    revenueBreakdown: crmDataReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // TODO: verify this further if seeting false causes issues on other endpoints
    // }).concat(debugMiddleware, cacheMiddleware),
    }).concat(debugMiddleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppDispatch: () => AppDispatch = useDispatch

export default store
