import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import debuggingService from 'store/services/debuggingService'
import {
  SummaryResponse,
  TrendSummary,
  Event,
  Source,
  LiveEvent,
} from 'types/debugging.model'
import { graphQuery } from 'types/dashboard.model'

export interface DebuggingSlice {
  loading: boolean
  summaryLoading: boolean
  trendSummaryLoading: boolean
  error: any
  summary: SummaryResponse
  trendSummary: TrendSummary
  eventBreakdown: Event[]
  eventFailure: Event[]
  sources: Source[]
  liveEvents: LiveEvent[]
}

const initState: DebuggingSlice = {
  loading: false,
  summaryLoading: false,
  trendSummaryLoading: false,
  error: null,
  summary: {
    event_failed: { value: 0, graph_data: [] },
    event_received: { value: 0, graph_data: [] },
    event_rate: { value: 0, graph_data: [] },
  },
  trendSummary: {},
  eventBreakdown: [],
  eventFailure: [],
  sources: [],
  liveEvents: [],
}

export const getSummary = createAsyncThunk(
  'debugging/getSummary',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await debuggingService.getSummary(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getTrendSummary = createAsyncThunk(
  'debugging/getTrendSummary',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await debuggingService.getTrendSummary(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getEventBreakdown = createAsyncThunk(
  'debugging/getEventBreakdown',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await debuggingService.getEventBreakdown(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getEventFailure = createAsyncThunk(
  'debugging/getEventFailure',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await debuggingService.getEventFailure(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getSources = createAsyncThunk(
  'debugging/getSources',
  async (companyId: string, { rejectWithValue }) => {
    const response = await debuggingService.getSources(companyId)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getLiveEvents = createAsyncThunk(
  'debugging/getLiveEvents',
  async (companyId: string, { rejectWithValue }) => {
    const response = await debuggingService.getLiveEvents(companyId)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

const debuggingSlice = createSlice({
  name: 'debugging',
  initialState: initState,
  reducers: {
    reset: () => {
      return initState
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getSummary.pending, (state) => {
        state.summaryLoading = true
      })
      .addCase(getSummary.fulfilled, (state, action) => {
        state.summaryLoading = false
        state.error = null
        state.summary = action.payload.data
      })
      .addCase(getSummary.rejected, (state, action) => {
        state.summaryLoading = false
        state.summary = {
          event_failed: { value: 0, graph_data: [] },
          event_received: { value: 0, graph_data: [] },
          event_rate: { value: 0, graph_data: [] },
        }
        state.error = action.payload
      })
    builder
      .addCase(getTrendSummary.pending, (state) => {
        state.trendSummaryLoading = true
      })
      .addCase(getTrendSummary.fulfilled, (state, action) => {
        state.trendSummaryLoading = false
        state.error = null
        state.trendSummary = action.payload.data
      })
      .addCase(getTrendSummary.rejected, (state, action) => {
        state.trendSummaryLoading = false
        state.trendSummary = {}
        state.error = action.payload
      })
    builder
      .addCase(getEventBreakdown.pending, (state) => {
        state.loading = true
      })
      .addCase(getEventBreakdown.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.eventBreakdown = action.payload.data
      })
      .addCase(getEventBreakdown.rejected, (state, action) => {
        state.loading = false
        state.eventBreakdown = []
        state.error = action.payload
      })
    builder
      .addCase(getEventFailure.pending, (state) => {
        state.loading = true
      })
      .addCase(getEventFailure.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.eventFailure = action.payload.data
      })
      .addCase(getEventFailure.rejected, (state, action) => {
        state.loading = false
        state.eventFailure = []
        state.error = action.payload
      })
    builder
      .addCase(getSources.pending, (state) => {
        state.loading = true
      })
      .addCase(getSources.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.sources = action.payload.data
      })
      .addCase(getSources.rejected, (state, action) => {
        state.loading = false
        state.sources = []
        state.error = action.payload
      })
    builder
      .addCase(getLiveEvents.pending, (state) => {
        state.loading = true
      })
      .addCase(getLiveEvents.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.liveEvents = action.payload.data
      })
      .addCase(getLiveEvents.rejected, (state, action) => {
        state.loading = false
        state.liveEvents = []
        state.error = action.payload
      })
  },
})

export const { reset } = debuggingSlice.actions

export default debuggingSlice.reducer
