import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import dashboardService from 'store/services/dashboardService'

import salesService from 'store/services/salesService'
import {
  funnelPerformanceDetails,
  funnelPerformanceQuerry,
  graphQuery,
  historicData,
} from 'types/dashboard.model'
import {
  BestProgressAllocated,
  BestProgressPaidout,
  IndividualDetails,
  IndividualStats,
  OverallStats,
  SalesMetrics,
  SalesRevenueSummary,
  SalesTeamMemberList,
  SalesVoulume,
  SourceStatus,
  SourceStatusInManager,
  TeamData,
  TeamDetails,
  TeamMemberList,
  TeamPerformanceSummery,
  TeamRating,
} from 'types/sales.model'

export interface SalesInitState {
  salesDashboardTab: string
  salesManagerTab: string
  loading: boolean
  error: any
  salesVolumeData: SalesVoulume
  salesMetricsData: SalesMetrics[]
  salesRevenueSummary: SalesRevenueSummary
  sourceStatus: SourceStatus[]
  salesPerformancePercentage: number
  salesPerformanceGraph: historicData[]
  currentVolume: number
  previousVolume: number
  facebookFunnelData: funnelPerformanceDetails
  googleFunnelData: funnelPerformanceDetails
  tiktokFunnelData: funnelPerformanceDetails
  teamRating: TeamRating[]
  salesTeamOverview: TeamData[]
  teamLeaderBoard: TeamMemberList[]
  sourceStatusInManager: SourceStatusInManager[]
  bestProgressAllocated: BestProgressAllocated[]
  bestprogressPaidout: BestProgressPaidout[]
  overallStats: OverallStats
  teamPerformanceSummery: TeamPerformanceSummery
  individualDetails: IndividualDetails
  teamPerformanceIndividual: TeamPerformanceSummery
  sourceStatusIndividual: SourceStatusInManager[]
  leaderBoardIndivideal: TeamMemberList[]
  salesTeamMemberList: SalesTeamMemberList[]
  teamDetails: TeamDetails
  individualStats: IndividualStats
}

const initState: SalesInitState = {
  salesDashboardTab: 'Sales Overview',
  salesManagerTab: 'Team Overview',
  loading: false,
  error: null,
  salesVolumeData: { from: '', percentage: 0, current: 0, previous: 0 },
  salesMetricsData: [],
  salesRevenueSummary: { total: 0, historic_data: [], percentage: 0 },
  sourceStatus: [],
  salesPerformancePercentage: 0,
  salesPerformanceGraph: [],
  currentVolume: 0,
  previousVolume: 0,
  facebookFunnelData: {
    funnel_data: {},
    overall_conversion_time: '',
    prev_overall_conversion_time: '',
    revenue: 0,
  },
  googleFunnelData: {
    funnel_data: {},
    overall_conversion_time: '',
    prev_overall_conversion_time: '',
    revenue: 0,
  },
  tiktokFunnelData: {
    funnel_data: {},
    overall_conversion_time: '',
    prev_overall_conversion_time: '',
    revenue: 0,
  },
  teamRating: [],
  salesTeamOverview: [],
  sourceStatusInManager: [],
  teamLeaderBoard: [],
  bestProgressAllocated: [],
  bestprogressPaidout: [],
  overallStats: {
    team_allocated: 0,
    team_paidout: 0,
    sales_team_id: '',
    conversion_rate: 0,
  },
  teamPerformanceSummery: { total: 0, historic_data: [], percentage: 0 },
  individualDetails: {
    sales_team_name: '',
    sales_person_id: '',
    sales_team_id: '',
    image_url: '',
    role: '',
    paidouts: 0,
    name: '',
    target: 0,
  },
  teamPerformanceIndividual: { total: 0, historic_data: [], percentage: 0 },
  sourceStatusIndividual: [],
  leaderBoardIndivideal: [],
  salesTeamMemberList: [],
  teamDetails: {
    sales_team_name: '',
    sales_team_id: '',
    sales_team_manager: '',
  },
  individualStats: {
    total_allocated: 0,
    total_paidout: 0,
    sales_team_id: '',
    conversion_rate: 0,
  },
}

export const getFunnelPerformanceFacebook = createAsyncThunk(
  'dashboard/getFunnelPerformanceFacebook',
  async (request: funnelPerformanceQuerry, { rejectWithValue }) => {
    const response = await dashboardService.getFunnelPerformance(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getFunnelPerformanceGoogle = createAsyncThunk(
  'dashboard/getFunnelPerformanceGoogle',
  async (request: funnelPerformanceQuerry, { rejectWithValue }) => {
    const response = await dashboardService.getFunnelPerformance(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getFunnelPerformanceTiktok = createAsyncThunk(
  'dashboard/getFunnelPerformanceTiktok',
  async (request: funnelPerformanceQuerry, { rejectWithValue }) => {
    const response = await dashboardService.getFunnelPerformance(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getSalesVolume = createAsyncThunk(
  'sales/getSalesVolume',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await salesService.getSalesVolume(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getSalesMetrics = createAsyncThunk(
  'sales/getSalesMetrics',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await salesService.getSalesMetrics(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getSalesRevenueSummary = createAsyncThunk(
  'sales/getSourceRevenue',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await salesService.getSalesRevenueSummary(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getSourceStatus = createAsyncThunk(
  'sales/getSourceStatus',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await salesService.getSourceStatus(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getSalesPerformance = createAsyncThunk(
  'sales/getSalesPerformance',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await salesService.getSalesPerformance(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getTeamRating = createAsyncThunk(
  'sales/getTeamRating',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await salesService.getTeamRating(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getSalesTeamOverview = createAsyncThunk(
  'sales/getSalesTeamOverview',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await salesService.getSalesTeamOverview(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getTeamLeaderboard = createAsyncThunk(
  'sales/getTeamLeaderBoard',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await salesService.getTeamLeaderboard(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getSourcesStatusInManager = createAsyncThunk(
  'sales/getSourceStatusInManager',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await salesService.getSourcesStatusInManager(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getBestProgressAllocated = createAsyncThunk(
  'sales/getBestProgressAllocated',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await salesService.getBestProgressAllocated(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getBestProgressPaidout = createAsyncThunk(
  'sales/getBestProgressPaidout',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await salesService.getBestProgressPaidout(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getOverallStats = createAsyncThunk(
  'sales/getOverallStats',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await salesService.getOverallStats(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getTeamPerformanceSummery = createAsyncThunk(
  'sales/getTeamPerformanceSummery',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await salesService.getTeamPerformanceSummery(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getIndividualDetails = createAsyncThunk(
  'sales/getIndividualDetails',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await salesService.getIndividualDetails(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getSourcesStatusInIndividual = createAsyncThunk(
  'sales/getSourceStatusInIndividual',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await salesService.getSourcesStatusInIndividual(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getLeaderBoardIndividual = createAsyncThunk(
  'sales/getTeamLeaderBoardIndividual',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await salesService.getLeaderBoardIndividual(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getTeamIndividualSummery = createAsyncThunk(
  'sales/getTeamIndividualSummery',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await salesService.getTeamIndividualSummery(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getSalesTeamMemberList = createAsyncThunk(
  'sales/getSalesTeamMemberList',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await salesService.getSalesTeamMemberList(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getTeamDetails = createAsyncThunk(
  'sales/getSalesTeamDetails',
  async (teamId: string, { rejectWithValue }) => {
    const response = await salesService.getTeamDetails(teamId)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getIndividualStats = createAsyncThunk(
  'sales/getIndividualStats',
  async (request: graphQuery, { rejectWithValue }) => {
    const response = await salesService.getIndividualStats(request)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

const workspaceSlice = createSlice({
  name: 'sales',
  initialState: initState,
  reducers: {
    setSalesDashboardTab: (state, action: PayloadAction<string>) => {
      state.salesDashboardTab = action.payload
    },
    setSalesManagerTab: (state, action: PayloadAction<string>) => {
      state.salesManagerTab = action.payload
    },
    reset: () => {
      return initState
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getFunnelPerformanceFacebook.pending, (state) => {
        state.loading = true
      })
      .addCase(getFunnelPerformanceFacebook.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.facebookFunnelData = action.payload.data
      })
      .addCase(getFunnelPerformanceFacebook.rejected, (state, action) => {
        state.loading = false
        state.facebookFunnelData = {
          funnel_data: {},
          overall_conversion_time: '',
          prev_overall_conversion_time: '',
          revenue: 0,
        }
        state.error = action.payload
      })
    builder
      .addCase(getFunnelPerformanceGoogle.pending, (state) => {
        state.loading = true
      })
      .addCase(getFunnelPerformanceGoogle.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.googleFunnelData = action.payload.data
      })
      .addCase(getFunnelPerformanceGoogle.rejected, (state, action) => {
        state.loading = false
        state.googleFunnelData = {
          funnel_data: {},
          overall_conversion_time: '',
          prev_overall_conversion_time: '',
          revenue: 0,
        }
        state.error = action.payload
      })
    builder
      .addCase(getFunnelPerformanceTiktok.pending, (state) => {
        state.loading = true
      })
      .addCase(getFunnelPerformanceTiktok.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.tiktokFunnelData = action.payload.data
      })
      .addCase(getFunnelPerformanceTiktok.rejected, (state, action) => {
        state.loading = false
        state.tiktokFunnelData = {
          funnel_data: {},
          overall_conversion_time: '',
          prev_overall_conversion_time: '',
          revenue: 0,
        }
        state.error = action.payload
      })
    builder
      .addCase(getSalesVolume.pending, (state) => {
        state.loading = true
      })
      .addCase(getSalesVolume.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.salesVolumeData = action.payload.data
      })
      .addCase(getSalesVolume.rejected, (state, action) => {
        state.loading = false
        state.salesVolumeData = {
          from: '',
          percentage: 0,
          current: 0,
          previous: 0,
        }
        state.error = action.payload
      })
    builder
      .addCase(getSalesMetrics.pending, (state) => {
        state.loading = true
      })
      .addCase(getSalesMetrics.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.salesMetricsData = action.payload.data
      })
      .addCase(getSalesMetrics.rejected, (state, action) => {
        state.loading = false
        state.salesMetricsData = []
        state.error = action.payload
      })
    builder
      .addCase(getSourceStatus.pending, (state) => {
        state.loading = true
      })
      .addCase(getSourceStatus.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.sourceStatus = action.payload.data
      })
      .addCase(getSourceStatus.rejected, (state, action) => {
        state.loading = false
        state.sourceStatus = []
        state.error = action.payload
      })
    builder
      .addCase(getSalesRevenueSummary.pending, (state) => {
        state.loading = true
      })
      .addCase(getSalesRevenueSummary.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.salesRevenueSummary = action.payload.data
      })
      .addCase(getSalesRevenueSummary.rejected, (state, action) => {
        state.loading = false
        state.salesRevenueSummary = {
          total: 0,
          historic_data: [],
          percentage: 0,
        }
        state.error = action.payload
      })
    builder
      .addCase(getSalesPerformance.pending, (state) => {
        state.loading = true
      })
      .addCase(getSalesPerformance.fulfilled, (state, action) => {
        state.loading = false
        state.salesPerformancePercentage = action.payload.data.percentage
        state.salesPerformanceGraph = action.payload.data.historic_data
        state.currentVolume = action.payload.data.current_volume
        state.previousVolume = action.payload.data.previous_volume
      })
      .addCase(getSalesPerformance.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.salesPerformancePercentage = 0
        state.salesPerformanceGraph = []
        state.currentVolume = 0
        state.previousVolume = 0
      })
    builder
      .addCase(getTeamRating.pending, (state) => {
        state.loading = true
      })
      .addCase(getTeamRating.fulfilled, (state, action) => {
        state.loading = false
        state.teamRating = action.payload.data
      })
      .addCase(getTeamRating.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.teamRating = []
      })
    builder
      .addCase(getSalesTeamOverview.pending, (state) => {
        state.loading = true
      })
      .addCase(getSalesTeamOverview.fulfilled, (state, action) => {
        state.loading = false
        state.salesTeamOverview = action.payload.data
      })
      .addCase(getSalesTeamOverview.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.salesTeamOverview = []
      })
    builder
      .addCase(getSourcesStatusInManager.pending, (state) => {
        state.loading = true
      })
      .addCase(getSourcesStatusInManager.fulfilled, (state, action) => {
        state.loading = false
        state.sourceStatusInManager = action.payload.data
      })
      .addCase(getSourcesStatusInManager.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.sourceStatusInManager = []
      })
    builder
      .addCase(getTeamLeaderboard.pending, (state) => {
        state.loading = true
      })
      .addCase(getTeamLeaderboard.fulfilled, (state, action) => {
        state.loading = false
        state.teamLeaderBoard = action.payload.data
      })
      .addCase(getTeamLeaderboard.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.teamLeaderBoard = []
      })
    builder
      .addCase(getBestProgressAllocated.pending, (state) => {
        state.loading = true
      })
      .addCase(getBestProgressAllocated.fulfilled, (state, action) => {
        state.loading = false
        state.bestProgressAllocated = action.payload.data
      })
      .addCase(getBestProgressAllocated.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.bestProgressAllocated = []
      })
    builder
      .addCase(getBestProgressPaidout.pending, (state) => {
        state.loading = true
      })
      .addCase(getBestProgressPaidout.fulfilled, (state, action) => {
        state.loading = false
        state.bestprogressPaidout = action.payload.data
      })
      .addCase(getBestProgressPaidout.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.bestprogressPaidout = []
      })
    builder
      .addCase(getOverallStats.pending, (state) => {
        state.loading = true
      })
      .addCase(getOverallStats.fulfilled, (state, action) => {
        state.loading = false
        state.overallStats = action.payload.data
      })
      .addCase(getOverallStats.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.overallStats = {
          conversion_rate: 0,
          sales_team_id: '',
          team_allocated: 0,
          team_paidout: 0,
        }
      })
    builder
      .addCase(getTeamPerformanceSummery.pending, (state) => {
        state.loading = true
      })
      .addCase(getTeamPerformanceSummery.fulfilled, (state, action) => {
        state.loading = false
        state.teamPerformanceSummery = action.payload.data
      })
      .addCase(getTeamPerformanceSummery.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.teamPerformanceSummery = {
          total: 0,
          historic_data: [],
          percentage: 0,
        }
      })
    builder
      .addCase(getIndividualDetails.pending, (state) => {
        state.loading = true
      })
      .addCase(getIndividualDetails.fulfilled, (state, action) => {
        state.loading = false
        state.individualDetails = action.payload.data
      })
      .addCase(getIndividualDetails.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.individualDetails = {
          sales_team_name: '',
          sales_person_id: '',
          sales_team_id: '',
          image_url: '',
          role: '',
          paidouts: 0,
          name: '',
          target: 0,
        }
      })
    builder
      .addCase(getTeamIndividualSummery.pending, (state) => {
        state.loading = true
      })
      .addCase(getTeamIndividualSummery.fulfilled, (state, action) => {
        state.loading = false
        state.teamPerformanceIndividual = action.payload.data
      })
      .addCase(getTeamIndividualSummery.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.teamPerformanceIndividual = {
          total: 0,
          historic_data: [],
          percentage: 0,
        }
      })
    builder
      .addCase(getSourcesStatusInIndividual.pending, (state) => {
        state.loading = true
      })
      .addCase(getSourcesStatusInIndividual.fulfilled, (state, action) => {
        state.loading = false
        state.sourceStatusIndividual = action.payload.data
      })
      .addCase(getSourcesStatusInIndividual.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.sourceStatusIndividual = []
      })
    builder
      .addCase(getLeaderBoardIndividual.pending, (state) => {
        state.loading = true
      })
      .addCase(getLeaderBoardIndividual.fulfilled, (state, action) => {
        state.loading = false
        state.leaderBoardIndivideal = action.payload.data
      })
      .addCase(getLeaderBoardIndividual.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.leaderBoardIndivideal = []
      })
    builder
      .addCase(getSalesTeamMemberList.pending, (state) => {
        state.loading = true
      })
      .addCase(getSalesTeamMemberList.fulfilled, (state, action) => {
        state.loading = false
        state.salesTeamMemberList = action.payload.data
      })
      .addCase(getSalesTeamMemberList.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.salesTeamMemberList = []
      })
    builder
      .addCase(getTeamDetails.pending, (state) => {
        state.loading = true
      })
      .addCase(getTeamDetails.fulfilled, (state, action) => {
        state.loading = false
        state.teamDetails = action.payload.data
      })
      .addCase(getTeamDetails.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.teamDetails = {
          sales_team_name: '',
          sales_team_id: '',
          sales_team_manager: '',
        }
      })
    builder
      .addCase(getIndividualStats.pending, (state) => {
        state.loading = true
      })
      .addCase(getIndividualStats.fulfilled, (state, action) => {
        state.loading = false
        state.individualStats = action.payload.data
      })
      .addCase(getIndividualStats.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.individualStats = {
          conversion_rate: 0,
          sales_team_id: '',
          total_allocated: 0,
          total_paidout: 0,
        }
      })
  },
})

export const { setSalesDashboardTab, setSalesManagerTab, reset } =
  workspaceSlice.actions

export default workspaceSlice.reducer
