import HttpClient from 'http/httpClient'
import {
  UserService as IUserService,
  User,
  GetUserInfo,
  Permission,
  Role,
  DeleteUserResponse,
  EditUserBody,
  EditUserResponse,
  ToggleUserBody,
  ToggleUserResponse,
  ResendInviteBody,
  ResendInviteResponse,
  UserWithId,
  TeamList,
  createTeam,
  updateTeamRequest,
  updateTeam,
  TeamRequest,
  InviteSetupUserRequest,
} from 'types/users.model'

const UserService = (): IUserService => {
  return {
    postUser: async (userData: User): HttpPromise<User> => {
      const response = await HttpClient.post('/api/users/', {
        data: userData,
      })
      return response.data as User
    },
    getUser: async (companyId: string): HttpPromise<GetUserInfo> => {
      const response = await HttpClient.get(`/api/users-list/${companyId}`)
      return response
    },
    getPermission: async (): HttpPromise<Permission[]> => {
      const response = await HttpClient.get<Permission[]>('/api/permissions/')
      return response
    },
    getRole: async (): HttpPromise<Role[]> => {
      const response = await HttpClient.get<Role[]>('/api/roles/')
      return response
    },
    deleteUser: async (userId: string): HttpPromise<DeleteUserResponse> => {
      const response = await HttpClient.delete(`/api/users/${userId}/`)
      return response
    },
    editUser: async (
      userId: string | undefined,
      userData: EditUserBody,
    ): HttpPromise<EditUserResponse> => {
      const response = await HttpClient.put(
        `/api/users/${userId}/${
          userData.revokeInvitation !== undefined
            ? `?revoke-invitation=${userData.revokeInvitation}`
            : ''
        }${
          userData.active !== undefined
            ? `${
                userData.revokeInvitation !== undefined ? `&active` : `?active`
              }=${userData.active}`
            : ''
        }`,
        {
          data: userData,
        },
      )
      return response.data
    },
    toggleUser: async (
      userId: string,
      toggleData: ToggleUserBody,
    ): HttpPromise<ToggleUserResponse> => {
      const response = await HttpClient.put(
        `/api/users/${userId}/toggle_activation/`,
        {
          data: toggleData,
        },
      )
      return response
    },
    resendInvite: async (
      userId: string,
      resendData: ResendInviteBody,
    ): HttpPromise<ResendInviteResponse> => {
      const response = await HttpClient.post(`/api/users/${userId}/resend/`, {
        data: resendData,
      })
      return response.data
    },
    convertUserByInvite: (request: UserWithId): HttpPromise<User> => {
      return HttpClient.post(
        `/api/users/${request.id}/convert-to-user/?save_only=${request.saveOnly}`,
        {
          data: request.data,
        },
      )
    },
    inviteSetupUser: (request: InviteSetupUserRequest): HttpPromise<User> => {
      return HttpClient.put(`/api/users/${request.userId}/send-invitation/`, {
        data: request.data,
      })
    },
    getTeamList: (companyId: string): HttpPromise<TeamList[]> => {
      return HttpClient.get(`/api/sales/team-management/${companyId}/`)
    },
    getUsersOfTeam: (req: TeamRequest): HttpPromise<TeamList[]> => {
      return HttpClient.get(
        `/api/users-list/${req.companyId}/?team=${req.teamId}`,
      )
    },
    createTeam: (request: createTeam): HttpPromise<TeamList[]> => {
      return HttpClient.post(
        `/api/sales/team-management/${request.companyId}/`,
        {
          data: request.team_name,
        },
      )
    },
    updateTeam: (request: updateTeamRequest): HttpPromise<updateTeam> => {
      return HttpClient.put(
        `/api/sales/team-management/team-details/${request.teamId}/`,
        {
          data: request.body,
        },
      )
    },
  }
}

export default UserService()
