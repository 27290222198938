import httpClient from 'http/httpClient'
import {
  SubscriptionService as ISubscriptionService,
  SubscriptionCheckoutRequest,
  SubscriptionCheckoutResponse,
  SubscriptionInfo,
  SubscriptionsDetails,
  PaymentSuccessRequest,
  CustomPlan,
  updatePlanRequest,
} from 'types/subscriptions.modal'

const SubscriptionService = (): ISubscriptionService => {
  return {
    getSubscriptionDetails: (
      companyId: string,
    ): HttpPromise<SubscriptionsDetails[]> => {
      return httpClient.get(
        `/api/dashboard/payment/${companyId}/get-subscriptions-plans/`,
      )
    },
    postSubscription: (
      req: SubscriptionCheckoutRequest,
    ): HttpPromise<SubscriptionCheckoutResponse> => {
      return httpClient.post(
        `/api/dashboard/payment/${req.companyId}/checkout/`,
        { data: { id: req.subscriptionId } },
      )
    },
    updateSubscription: (
      req: updatePlanRequest,
    ): HttpPromise<SubscriptionCheckoutResponse> => {
      return httpClient.post(
        `/api/dashboard/payment/${req.companyId}/update-subscription/`,
        { data: { id: req.subscriptionId, price: req.price, plan: req.name } },
      )
    },
    paymentSuccess: (req: PaymentSuccessRequest) => {
      return httpClient.get(
        `/api/dashboard/payment/${req.companyId}/payment-success/?session_id=${req.sessionId}`,
      )
    },
    getSubscriptionInfo: (companyId: string): HttpPromise<SubscriptionInfo> => {
      return httpClient.get(
        `/api/dashboard/plan/${companyId}/get-subscription-details/`,
      )
    },
    cancelSubscription: (companyId: string): HttpPromise<SubscriptionInfo> => {
      return httpClient.post(
        `/api/dashboard/plan/${companyId}/cancel-subscription/`,
      )
    },
    createCustomPlan: (request: CustomPlan): HttpPromise<SubscriptionInfo> => {
      return httpClient.post(
        `/api/dashboard/payment/${request.id}/create-custom-price/`,
        {
          data: request.body,
        },
      )
    },
  }
}

export default SubscriptionService()
