import { Middleware } from '@reduxjs/toolkit'

interface TimingInfo {
  actionType: string
  startTime: number
  endTime: number
  duration: number
}

const timings: TimingInfo[] = []

export const debugMiddleware: Middleware = () => (next) => (action) => {
  if (action.type === 'adsCrmData/fetch/pending') {
    const startTime = performance.now()
    const result = next(action)

    const endAction = (endAction: any) => {
      if (
        endAction.type === 'adsCrmData/fetch/fulfilled' ||
        endAction.type === 'adsCrmData/fetch/rejected'
      ) {
        const endTime = performance.now()
        const duration = endTime - startTime

        timings.push({
          actionType: action.type,
          startTime,
          endTime,
          duration,
        })

        return next(endAction)
      }
      return next(endAction)
    }

    return Promise.resolve(result).then(endAction, endAction)
  }

  return next(action)
}

export const getTimings = () => timings
