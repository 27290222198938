import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import wallboardService from 'store/services/wallboardService'
import { WallboardData, WallboardUrlRes } from 'types/wallboards.model'

export interface WallboardInitState {
  loading: boolean
  error: any
  wallboardData: WallboardData
  wallboardUrl: WallboardUrlRes
  wallboardLoading: boolean
}

const initState: WallboardInitState = {
  loading: false,
  error: null,
  wallboardData: {
    today: {},
    this_month: {},
    this_week: {},
  },
  wallboardUrl: { items: { sales_wallboard: '' } },
  wallboardLoading: false,
}

export const getWallboardData = createAsyncThunk(
  'wallboard/getWallbaordData',
  async (url: string, { rejectWithValue }) => {
    const response = await wallboardService.getWallboardData(url)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getWallboardUrl = createAsyncThunk(
  'wallboard/getWallbaordUrl',
  async (comapnyId: string, { rejectWithValue }) => {
    const response = await wallboardService.getWallboardUrl(comapnyId)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

const wallboardSlice = createSlice({
  name: 'wallboard',
  initialState: initState,
  reducers: {
    reset: () => {
      return initState
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getWallboardData.pending, (state) => {
        state.wallboardLoading = true
      })
      .addCase(getWallboardData.fulfilled, (state, action) => {
        state.wallboardLoading = false
        state.error = null
        state.wallboardData = action.payload.data
      })
      .addCase(getWallboardData.rejected, (state, action) => {
        state.wallboardLoading = false
        state.wallboardData = {
          today: {},
          this_month: {},
          this_week: {},
        }
        state.error = action.payload
      })
    builder
      .addCase(getWallboardUrl.pending, (state) => {
        state.loading = true
      })
      .addCase(getWallboardUrl.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.wallboardUrl = action.payload.data
      })
      .addCase(getWallboardUrl.rejected, (state, action) => {
        state.loading = false
        state.wallboardUrl = { items: { sales_wallboard: '' } }
        state.error = action.payload
      })
  },
})

export const { reset } = wallboardSlice.actions

export default wallboardSlice.reducer
