import httpClient from 'http/httpClient'
import {
  signupRequest,
  AuthService as IAuthService,
  loginRequest,
  verifyEmail,
  password,
  email,
  passwordResetConfirm,
  signupRequestAgency,
  inviteUserSignup,
} from 'types/auth.model'

const AuthService = (): IAuthService => {
  return {
    postSignup: async (userData: signupRequest): Promise<signupRequest> => {
      const response = await httpClient.post('/api/auth/signup/', {
        data: userData,
        includeToken: false,
      })

      return response.data as signupRequest
    },
    postSignupAgency: async (
      userData: signupRequestAgency,
    ): Promise<signupRequestAgency> => {
      const response = await httpClient.post('/api/auth/signup/', {
        data: userData,
        includeToken: false,
      })

      return response.data as signupRequestAgency
    },
    postLogin: async (loginData: loginRequest): Promise<loginRequest> => {
      const response = await httpClient.post('/api/auth/login/', {
        data: loginData,
        includeToken: false,
      })
      return response.data as loginRequest
    },
    postOtp: async (otp: verifyEmail): Promise<verifyEmail> => {
      const response = await httpClient.post('/api/auth/verify-email/', {
        data: otp,
        includeToken: false,
      })
      return response.data as verifyEmail
    },
    postResendOtp: async (email: email): Promise<email> => {
      const response = await httpClient.post('/api/auth/signup/resend-otp/', {
        data: email,
      })
      return response.data as email
    },
    postPassword: async (password: password): Promise<password> => {
      const response = await httpClient.post('/api/auth/set-password/', {
        data: password,
        includeToken: true,
      })
      return response.data as password
    },
    postEmail: async (email: email): Promise<email> => {
      const response = await httpClient.post('/api/auth/password-reset/', {
        data: email,
        includeToken: false,
      })
      return response.data as email
    },
    postPasswordResetConfirm: async (
      newPassword: passwordResetConfirm,
    ): Promise<passwordResetConfirm> => {
      const response = await httpClient.post(
        '/api/auth/password-reset-confirm/',
        {
          data: newPassword,
          includeToken: false,
        },
      )
      return response.data as passwordResetConfirm
    },
    putInviteUserSignup: async (
      newPassword: inviteUserSignup,
    ): Promise<inviteUserSignup> => {
      const response = await httpClient.put('/api/users/accept/', {
        data: newPassword,
        includeToken: false,
      })
      return response.data as inviteUserSignup
    },
  }
}

export default AuthService()
