import authService from 'store/services/authService'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export interface AuthSlice {
  loading: boolean
  error: any
}

const initState: AuthSlice = {
  loading: false,
  error: null,
}

export const postSignup = createAsyncThunk(
  'auth/postSignup',
  authService.postSignup,
)
export const postSignupAgency = createAsyncThunk(
  'auth/postSignupAgency',
  authService.postSignupAgency,
)

export const postLogin = createAsyncThunk(
  'auth/postLogin',
  authService.postLogin,
)

export const postOtp = createAsyncThunk('auth/verifyEmail', authService.postOtp)

export const postResendOtp = createAsyncThunk(
  'auth/resendOtp',
  authService.postResendOtp,
)

export const postPassword = createAsyncThunk(
  'auth/setPassword',
  authService.postPassword,
)
export const postEmail = createAsyncThunk(
  'auth/postEmail',
  authService.postEmail,
)
export const postPasswordResetConfirm = createAsyncThunk(
  'auth/postPasswordResetConfirm',
  authService.postPasswordResetConfirm,
)
export const putInviteUserSignup = createAsyncThunk(
  'auth/putInviteUserSignup',
  authService.putInviteUserSignup,
)

const authSlice = createSlice({
  name: 'auth',
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postSignup.pending, (state) => {
        state.loading = true
      })
      .addCase(postSignup.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(postSignup.rejected, (state, action) => {
        state.loading = false

        state.error = action.payload
      })
    builder
      .addCase(postSignupAgency.pending, (state) => {
        state.loading = true
      })
      .addCase(postSignupAgency.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(postSignupAgency.rejected, (state, action) => {
        state.loading = false

        state.error = action.payload
      })
    builder
      .addCase(postLogin.pending, (state) => {
        state.loading = true
      })
      .addCase(postLogin.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(postLogin.rejected, (state, action) => {
        state.loading = false

        state.error = action.payload
      })
    builder
      .addCase(postOtp.pending, (state) => {
        state.loading = true
      })
      .addCase(postOtp.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(postOtp.rejected, (state, action) => {
        state.loading = false

        state.error = action.payload
      })
    builder
      .addCase(postPassword.pending, (state) => {
        state.loading = true
      })
      .addCase(postPassword.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(postPassword.rejected, (state, action) => {
        state.loading = false

        state.error = action.payload
      })
    builder
      .addCase(postEmail.pending, (state) => {
        state.loading = true
      })
      .addCase(postEmail.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(postEmail.rejected, (state, action) => {
        state.loading = false

        state.error = action.payload
      })
    builder
      .addCase(postResendOtp.pending, (state) => {
        state.loading = true
      })
      .addCase(postResendOtp.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(postResendOtp.rejected, (state, action) => {
        state.loading = false

        state.error = action.payload
      })
    builder
      .addCase(putInviteUserSignup.pending, (state) => {
        state.loading = true
      })
      .addCase(putInviteUserSignup.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(putInviteUserSignup.rejected, (state, action) => {
        state.loading = false

        state.error = action.payload
      })
  },
})
export default authSlice.reducer
