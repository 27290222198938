import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

const Loading = () => {
  return (
    <Box>
      <div className="charts-layout-loader ">
        <p className="loading-message">We are preparing your data</p>
        <CircularProgress />
      </div>
    </Box>
  )
}

export default Loading
