export const PAYMENT_SUCCESS = 'payment-success'
export const STAGE_LEAD = 'lead'
export const STAGE_APPROVED = 'approved'
export const STAGE_ALLOCATED = 'allocated'
export const STAGE_PAID_OUT = 'paid_out'
export const FILTER_SELECTED = 'filterSelected'
export const DISPLAY_FILTER = 'displayFilterSelected'
export const TARGET_FILTER_SELECTED = 'targetFilterSelected'
export const START_DATE_RANGE = 'startDateRange'
export const END_DATE_RANGE = 'endDateRange'
export const targetMonths = [
  {
    index: 1,
    name: 'January',
    days: 31,
  },
  {
    index: 2,
    name: 'February',
    days: 29,
  },
  {
    index: 3,
    name: 'March',
    days: 31,
  },
  {
    index: 4,
    name: 'April',
    days: 30,
  },
  {
    index: 5,
    name: 'May',
    days: 31,
  },
  {
    index: 6,
    name: 'June',
    days: 30,
  },
  {
    index: 7,
    name: 'July',
    days: 31,
  },
  {
    index: 8,
    name: 'August',
    days: 31,
  },
  {
    index: 9,
    name: 'September',
    days: 30,
  },
  {
    index: 10,
    name: 'October',
    days: 31,
  },
  {
    index: 11,
    name: 'November',
    days: 30,
  },
  {
    index: 12,
    name: 'December',
    days: 31,
  },
  {
    index: 1,
    name: 'January',
    days: 31,
  },
  {
    index: 2,
    name: 'February',
    days: 29,
  },
  {
    index: 3,
    name: 'March',
    days: 31,
  },
  {
    index: 4,
    name: 'April',
    days: 30,
  },
]
