import httpClient from 'http/httpClient'
import {
  TargetService as ITargetService,
  SalesTarget,
  SalesTargetRequest,
  Team,
  copyTarget,
  copyTargetRequest,
  MarketingTarget,
  MarketingTargetByMonth,
  MarketingTargetByMonthRequest,
  MarketingTargetByMonthUpdateRequest,
  CopyMarketingTargetRequest,
  Comparison,
} from 'types/targets.modal'

const TargetService = (): ITargetService => {
  return {
    getTargetRows: (companyId: string): HttpPromise<Team[]> => {
      return httpClient.get(`/api/sales/sales-target/${companyId}/`)
    },
    postTarget: (request: SalesTargetRequest): HttpPromise<SalesTarget> => {
      return httpClient.post(`/api/sales/sales-target/${request.companyId}/`, {
        data: request.body,
      })
    },
    postCopyTargets: (request: copyTargetRequest): HttpPromise<copyTarget> => {
      return httpClient.post(
        `/api/sales/copy-sales-target/${request.companyId}/`,
        {
          data: request.body,
        },
      )
    },
    getMarketingTarget: (companyId: string): HttpPromise<MarketingTarget[]> => {
      return httpClient.get(`/api/marketing/targets/${companyId}/`)
    },
    getMarketingTargetByMonth: (
      monthId: string,
    ): HttpPromise<MarketingTargetByMonth> => {
      return httpClient.get(`/api/marketing/sources/${monthId}/get-targets/`)
    },
    postMarketingTargetByMonth: (
      req: MarketingTargetByMonthRequest,
    ): HttpPromise<MarketingTargetByMonth> => {
      return httpClient.post(
        `/api/marketing/sources/${req.sourceId}/targets/`,
        { data: req.data },
      )
    },
    putMarketingTargetByMonth: (
      req: MarketingTargetByMonthUpdateRequest,
    ): HttpPromise<MarketingTargetByMonth> => {
      return httpClient.put(
        `/api/marketing/sources/${req.targetId}/update-targets/`,
        { data: req.data },
      )
    },
    postCopyMarketingTarget: (
      req: CopyMarketingTargetRequest,
    ): HttpPromise<string> => {
      return httpClient.post(
        `/api/marketing/sources/${req.sourceId}/copy-targets/`,
        { data: req.data },
      )
    },
    getComparison: (companyId: string): HttpPromise<Comparison[]> => {
      return httpClient.get(`/api/marketing/overall-targets/${companyId}/`)
    },
  }
}

export default TargetService()
