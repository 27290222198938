import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import reportingService from 'store/services/reportingService'
import {
  Alert,
  DataSource,
  InAppAlert,
  Metrics,
  Notification,
  notificationCount,
} from 'types/reporting.model'

export interface ReportingInitState {
  loading: boolean
  error: any
  metrics: Metrics
  dataSources: DataSource[]
  alert: Alert
  alerts: Alert[]
  inAppAlerts: InAppAlert[]
  notifications: Notification[]
  notificationsCount: notificationCount
}

const initState: ReportingInitState = {
  loading: false,
  error: null,
  metrics: { metrics: [], logical_operators: [], operators: [], schedules: [] },
  dataSources: [],
  alert: {
    id: '',
    name: '',
    alert_frequency: '',
    alert_notifications: [],
    data_source: '',
    data_source_name: '',
    data_source_url: '',
    trigger_conditions: {
      metric: { id: '', name: '' },
      operand: '',
      operator: '',
    },
    is_active: true,
  },
  alerts: [],
  inAppAlerts: [],
  notifications: [],
  notificationsCount: { unread_notification_count: 0 },
}

export const createAlert = createAsyncThunk(
  'dashboard/createAlert',
  reportingService.createAlert,
)

export const updateAlert = createAsyncThunk(
  'dashboard/updateAlert',
  reportingService.updateAlert,
)

export const getMetrics = createAsyncThunk(
  'dashboard/getMetrics',
  async (companyId: string, { rejectWithValue }) => {
    const response = await reportingService.getMetrics(companyId)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getDataSources = createAsyncThunk(
  'dashboard/getDataSources',
  async (companyId: string, { rejectWithValue }) => {
    const response = await reportingService.getDataSources(companyId)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getAlertById = createAsyncThunk(
  'dashboard/getAlertById',
  async (id: string, { rejectWithValue }) => {
    const response = await reportingService.getAlertById(id)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

// export const getAlerts = createAsyncThunk(
//   'dashboard/getAlerts',
//   async (companyId: string, { rejectWithValue }) => {
//     const response = await reportingService.getAlerts(companyId)
//     if (response.status === 200) {
//       return response
//     } else {
//       rejectWithValue(await response.json())
//       return response
//     }
//   },
// )

export const getAlerts = createAsyncThunk(
  'dashboard/getAlerts',
  reportingService.getAlerts,
)

export const toggleAlert = createAsyncThunk(
  'dashboard/toggleAlert',
  async (id: string, { rejectWithValue }) => {
    const response = await reportingService.toggleAlert(id)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const deleteAlert = createAsyncThunk(
  'dashboard/deleteAlert',
  reportingService.deleteAlert,
)

export const getInAppAlerts = createAsyncThunk(
  'dashboard/getInAppAlerts',
  async (companyId: string, { rejectWithValue }) => {
    const response = await reportingService.getInAppAlerts(companyId)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getAllNotifications = createAsyncThunk(
  'dashboard/getAllNotifications',
  async (_, { rejectWithValue }) => {
    const response = await reportingService.getAllNotifications()
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const getAllNotificationsCount = createAsyncThunk(
  'dashboard/getAllNotificationsCount',
  async (_, { rejectWithValue }) => {
    const response = await reportingService.getAllNotificationsCount()
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const patchReadNotification = createAsyncThunk(
  'dashboard/patchReadNotifications',
  reportingService.patchReadNotification,
)

const reportingSlice = createSlice({
  name: 'reporting',
  initialState: initState,
  reducers: {
    reset: () => {
      return initState
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(createAlert.pending, (state) => {
        state.loading = true
      })
      .addCase(createAlert.fulfilled, (state) => {
        state.loading = false
        state.error = null
      })
      .addCase(createAlert.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
    builder
      .addCase(getMetrics.pending, (state) => {
        state.loading = true
      })
      .addCase(getMetrics.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.metrics = action.payload.data
      })
      .addCase(getMetrics.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.metrics = {
          metrics: [],
          operators: [],
          logical_operators: [],
          schedules: [],
        }
      })
    builder
      .addCase(getDataSources.pending, (state) => {
        state.loading = true
      })
      .addCase(getDataSources.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.dataSources = action.payload.data
      })
      .addCase(getDataSources.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.dataSources = []
      })
    builder
      .addCase(getAlertById.pending, (state) => {
        state.loading = true
      })
      .addCase(getAlertById.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.alert = action.payload.data
      })
      .addCase(getAlertById.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.alert = {
          id: '',
          name: '',
          alert_frequency: '',
          alert_notifications: [],
          data_source: '',
          data_source_name: '',
          data_source_url: '',
          trigger_conditions: {
            metric: { id: '', name: '' },
            operand: '',
            operator: '',
          },
          is_active: true,
        }
      })
    builder
      .addCase(getAlerts.pending, (state) => {
        state.loading = true
      })
      .addCase(getAlerts.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.alerts = action.payload.data
      })
      .addCase(getAlerts.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.alerts = []
      })
    builder
      .addCase(toggleAlert.pending, (state) => {
        state.loading = true
      })
      .addCase(toggleAlert.fulfilled, (state, action) => {
        state.loading = false
        state.alerts = state.alerts.map((alert) =>
          alert.id === action.payload.data.id
            ? { ...alert, is_active: action.payload.data.is_active }
            : alert,
        )
        state.error = null
      })
      .addCase(toggleAlert.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
      })
    builder
      .addCase(getInAppAlerts.pending, (state) => {
        state.loading = true
      })
      .addCase(getInAppAlerts.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.inAppAlerts = action.payload.data
      })
      .addCase(getInAppAlerts.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.inAppAlerts = []
      })
    builder
      .addCase(getAllNotifications.pending, (state) => {
        state.loading = true
      })
      .addCase(getAllNotifications.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.notifications = action.payload.data
      })
      .addCase(getAllNotifications.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.notifications = []
      })
    builder
      .addCase(getAllNotificationsCount.pending, (state) => {
        state.loading = true
      })
      .addCase(getAllNotificationsCount.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.notificationsCount = action.payload.data
      })
      .addCase(getAllNotificationsCount.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload
        state.notificationsCount = { unread_notification_count: 0 }
      })
  },
})

export const { reset } = reportingSlice.actions

export default reportingSlice.reducer
