import { createAsyncThunk } from '@reduxjs/toolkit'
import { formatDateToYYYYMMDD } from 'helpers/dateFilterUtils'
import httpClient from 'http/httpClient'
import { RootState } from 'store'
import { RevenueBreakdownData } from 'types/crmRevenueBreakDown.model'

interface RevenueBreakdownApiResponse {
  count: number
  data: RevenueBreakdownData[]
}

interface FetchCrmDataParams {
  date_from: string // ISO date string
  date_to: string // ISO date string
  source?: string
}

export const fetchCrmRevenueBreakdown = createAsyncThunk<
  RevenueBreakdownData[],
  FetchCrmDataParams,
  { state: RootState; rejectValue: string }
>(
  'revenueBreakdownData/fetch',
  async (params, { rejectWithValue, getState }) => {
    try {
      const state = getState()
      const queryParams: Record<string, string | number> = {
        limit: 30000,
      }

      if (params.date_from && params.date_to) {
        const startDate = new Date(params.date_from)
        const endDate = new Date(params.date_to)
        // Assuming you need the previous period as well
        queryParams.date_from = formatDateToYYYYMMDD(startDate)
        queryParams.date_to = formatDateToYYYYMMDD(endDate)
      }

      if (params.source) {
        // TODO: enable source in param when the data gets too large
        // queryParams.source = params.source;
      }

      const companyId = state.profile.companyId
      const response = await httpClient.get<RevenueBreakdownApiResponse>(
        `api/sources/sales-funnel-revenue-breakdown/${companyId}/`,
        {
          params: queryParams,
        },
      )

      return response.data.data
    } catch (error) {
      console.error('Error fetching CRM data:', error)
      return rejectWithValue('Failed to fetch CRM data')
    }
  },
)

const CrmRevenueBreakdownService = () => {
  return {
    fetchCrmRevenueBreakdown,
  }
}

export default CrmRevenueBreakdownService()
