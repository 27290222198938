import * as Sentry from '@sentry/react'
import { TabViewProvider } from 'providers/TabViewProvider'
import { UserSettingsViewProvider } from 'providers/UserSettingsViewProvider'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Router from 'routes/Router'
import store from 'store'

import './index.scss'

if (import.meta.env.VITE_ENVIRONMENT === 'production') {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

// Create a QueryClient instance for React Query
const queryClient = new QueryClient()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <>
    <ToastContainer />
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <TabViewProvider>
          <UserSettingsViewProvider>
            <RouterProvider router={Router} />
          </UserSettingsViewProvider>
        </TabViewProvider>
      </QueryClientProvider>
    </Provider>
  </>,
)
