import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchCrmRevenueBreakdown } from "store/services/crmRevenueBreakDownService";
import { RevenueBreakdownData } from "types/crmRevenueBreakDown.model";
import { RangeKeyDict } from "react-date-range";

interface CrmDataState {
  revenueBreakdownData: RevenueBreakdownData[];
  isLoading: boolean;
  error: string | null;
  lastFetchedDateRange: RangeKeyDict | null;
}

const initialState: CrmDataState = {
  revenueBreakdownData: [],
  isLoading: false,
  error: null,
  lastFetchedDateRange: null,
};

const crmRevenuBreakdownSlice = createSlice({
  name: "revenueBreakdownData",
  initialState,
  reducers: {
    clearCrmData: (state) => {
      state.revenueBreakdownData = [];
      state.error = null;
    },
    setLastFetchedDateRange: (state, action: PayloadAction<RangeKeyDict>) => {
      state.lastFetchedDateRange = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCrmRevenueBreakdown.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        fetchCrmRevenueBreakdown.fulfilled,
        (state, action: PayloadAction<RevenueBreakdownData[]>) => {
          state.isLoading = false;
          state.revenueBreakdownData = action.payload;
        }
      )
      .addCase(fetchCrmRevenueBreakdown.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "An error occurred";
      });
  },
});

export const { clearCrmData, setLastFetchedDateRange } = crmRevenuBreakdownSlice.actions;

export default crmRevenuBreakdownSlice.reducer;
