import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import targetService from 'store/services/targetService'
import {
  Comparison,
  MarketingTarget,
  MarketingTargetByMonth,
  Team,
} from 'types/targets.modal'

export interface TargetInitState {
  loading: boolean
  error: any
  teamMemberList: Team[]
  marketingTarget: MarketingTarget[]
  marketingTargetByMonth: MarketingTargetByMonth
  comparison: Comparison[]
}

const initState: TargetInitState = {
  loading: false,
  error: null,
  teamMemberList: [],
  marketingTarget: [],
  marketingTargetByMonth: { month: 0, year: 0, targets: [] },
  comparison: [],
}

export const getTargetRows = createAsyncThunk(
  'target/getTargetRows',
  async (companyId: string, { rejectWithValue }) => {
    const response = await targetService.getTargetRows(companyId)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const postTarget = createAsyncThunk(
  'target/postTarget',
  targetService.postTarget,
)

export const postCopyTargets = createAsyncThunk(
  'target/postCopyTargets',
  targetService.postCopyTargets,
)

export const getMarketingTarget = createAsyncThunk(
  'dashboard/getMarketingTarget',
  async (companyId: string, { rejectWithValue }) => {
    const response = await targetService.getMarketingTarget(companyId)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const postMarketingTargetByMonth = createAsyncThunk(
  'target/postMarketingTarget',
  targetService.postMarketingTargetByMonth,
)

export const putMarketingTargetByMonth = createAsyncThunk(
  'target/putMarketingTarget',
  targetService.putMarketingTargetByMonth,
)

export const getMarketingTargetByMonth = createAsyncThunk(
  'dashboard/getMarketingTargetByMonth',
  async (monthId: string, { rejectWithValue }) => {
    const response = await targetService.getMarketingTargetByMonth(monthId)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

export const copyMarketingTarget = createAsyncThunk(
  'target/copyMarketingTarget',
  targetService.postCopyMarketingTarget,
)

export const getComparison = createAsyncThunk(
  'dashboard/getComparison',
  async (comapnyId: string, { rejectWithValue }) => {
    const response = await targetService.getComparison(comapnyId)
    if (response.status === 200) {
      return response
    } else {
      rejectWithValue(await response.json())
      return response
    }
  },
)

const targetSlice = createSlice({
  name: 'target',
  initialState: initState,
  reducers: {
    reset: () => {
      return initState
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getTargetRows.pending, (state) => {
        state.loading = true
      })
      .addCase(getTargetRows.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.teamMemberList = action.payload.data
      })
      .addCase(getTargetRows.rejected, (state, action) => {
        state.loading = false
        state.teamMemberList = []
        state.error = action.payload
      })
    builder
      .addCase(getMarketingTarget.pending, (state) => {
        state.loading = true
      })
      .addCase(getMarketingTarget.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.marketingTarget = action.payload.data
      })
      .addCase(getMarketingTarget.rejected, (state, action) => {
        state.loading = false
        state.marketingTarget = []
        state.error = action.payload
      })
    builder
      .addCase(getMarketingTargetByMonth.pending, (state) => {
        state.loading = true
      })
      .addCase(getMarketingTargetByMonth.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.marketingTargetByMonth = action.payload.data
      })
      .addCase(getMarketingTargetByMonth.rejected, (state, action) => {
        state.loading = false
        state.marketingTargetByMonth = { month: 0, year: 0, targets: [] }
        state.error = action.payload
      })
    builder
      .addCase(getComparison.pending, (state) => {
        state.loading = true
      })
      .addCase(getComparison.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.comparison = action.payload.data
      })
      .addCase(getComparison.rejected, (state, action) => {
        state.loading = false
        state.comparison = []
        state.error = action.payload
      })
  },
})

export const { reset } = targetSlice.actions

export default targetSlice.reducer
